import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as Vivvi } from "../../static/svg/vivvi.svg";
import { ReactComponent as Indeed } from "../../static/svg/indeed-icon.svg";
import { ReactComponent as Lumo } from "../../static/svg/sponsors/lumo.svg";

type Props = {
  headerText?: string;
};

const PresentingSponsors = (props: Props) => {
  return (
    <React.Fragment>
      <section id="eventsponsors" className="pt-12 pb-6">
        <Box className="my-6 overflow-hidden">
          <Typography
            variant="caption"
            fontSize={{ xs: "11px", sm: "11px" }}
            fontWeight={900}
            lineHeight={{ xs: "100%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className=" uppercase text-center flex justify-center"
          >
            {props.headerText}
          </Typography>
        </Box>
        <Grid
          container
          className=" lg:px-6 py-4 gap-4 md:pl-0 mx-auto place-content-center items-center justify-center"
        >
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/sc.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/providus-bank.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/shuttlers.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/elect-her.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/eu-co-funding.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/careBlock.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/healthtracka.jpg"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/fez.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/lasrra.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>

          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/ambulance.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/GDN.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/Elev8.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/HERCONOMY.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={2} md={2} lg={2}>
            <img
              src="../../asset/sponsors/baby.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-24 object-contain grayscale mx-auto"
            />
          </Grid>
          {/* <Grid item xs={5} sm={3} md={2} lg={2}>
            <Indeed className="w-[150px] h-[60px]  object-cover grayscale mx-auto" />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <Vivvi className="w-[150px] h-[60px]  object-cover grayscale mx-auto" />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <Lumo className="w-[150px] h-[60px]  object-cover grayscale mx-auto" />
          </Grid> */}
          {/* <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694094731/speakers/Mirza_ey7xlw.png"
              alt="Sponsors"
              className=" w-[175px] h-[58px] grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694094733/speakers/Cocoon_zqmbyw.png"
              alt="Sponsors"
              className=" w-[400.2px] h-8 md:h-240px] grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694095161/speakers/greenhouse_zpnyu7.png"
              alt="Sponsors"
              className=" w-[300px] h-[43px] grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694111992/speakers/ianacare-black_lpfsto.png"
              alt="Sponsors"
              className=" w-[299.75px] h-10 md:h-241.75px] grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/luminary.png"
              alt="Sponsors"
              className=" w-[299.75px] h-10 md:h-241.75px] grayscale mx-auto"
            />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/kindercare.png"
              alt="Sponsors"
              className=" w-[80px] h-18 md:h-24 grayscale mx-auto"
            />
          </Grid> */}
        </Grid>
      </section>
    </React.Fragment>
  );
};

export default PresentingSponsors;
