import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MHAccordion from "../Common/Accordion/MHAccordion";
import { FAQ_Consultants_Data } from "../../utils/faq-data";

type Props = {};

const Consultants = (props: Props) => {
  return (
    <React.Fragment>
      <section id="consultants" className="my-4">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "30px", sm: "32px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={"-0.04em"}
          className="mx-auto mb-6"
        >
          Consultants
        </Typography>

        {FAQ_Consultants_Data.map((faq, index) => (
          <MHAccordion
            DataId={`${faq.id}`}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </section>
    </React.Fragment>
  );
};

export default Consultants;
