import CoachPager from "../components/Coaching/CoachPager";
import EnterprisePlan from "../components/Landing/EnterprisePlan";
import EventAdvert from "../components/Landing/EventAdvert";
import FollowMH from "../components/Landing/FollowMH";
import LandingEvent from "../components/Landing/LandingEvent";
import LandingToolkit from "../components/Landing/LandingToolkit";
import MainView from "../components/Landing/MainView";
import StickyBottom from "../components/Landing/StickyBottom";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHFooter from "../components/Layout/MHFooter";
import BasicTabs from "../components/Landing/ResourcesTab";
import LandingResCategory from "../components/Landing/LandingResCategory";
import React from "react";
import StickyLegal from "../components/Landing/StickyLegal";
import SubscribeNewsDialog from "../components/Landing/SubscribeNewsDialog";
import MembershipWidget from "../components/Features/Landing/MembershipWidget";
import { Box } from "@mui/material";
import PlacementsSlider from "../components/Features/Placement/PlacementsSlider";
import FeaturedCompanies from "../components/Landing/FeaturedCompanies";
import HeroSlider from "../components/Landing/HeroSlider";
import { getCookie } from "../hooks/use-cookie";
import TakeSurvey from "../components/Landing/TakeSurvey";

const Index = () => {
  const [timer, setTimer] = React.useState(false);
  const [newsTimer, setNewsTimer] = React.useState(false);
  const [subTimer, setSubTimer] = React.useState(false);
  const [toolTimer, setToolTimer] = React.useState(false);

  const cookieValue = getCookie("_mhCookie");

  React.useEffect(() => {
    if (cookieValue === "") {
      const timer = setTimeout(() => {
        setTimer(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setTimer(false);
      return;
    }
  }, []);

  React.useEffect(() => {
    if (cookieValue === "") {
      const newsTimer = setTimeout(() => {
        setNewsTimer(true);
      }, 3000);
      return () => clearTimeout(newsTimer);
    } else {
      setNewsTimer(false);
      return;
    }
  }, []);

  const handleLegalClose = () => {
    setTimer(false);
  };
  const handleNewsClose = () => {
    setNewsTimer(false);
    setSubTimer(true);
  };
  const handleSubClose = () => {
    setSubTimer(false);
    setToolTimer(true);
  };
  const handleToolClose = () => {
    setToolTimer(false);
  };

  return (
    <React.Fragment>
      <MainView />

      <Box mt={10} mb={7}>
        <FeaturedCompanies />
      </Box>

      <HeroSlider />

      {/* <EnterprisePlan /> */}

      {/* <CoachPager /> */}

      {/* <PlacementsSlider /> */}

      {/* <LandingToolkit /> */}

      <TakeSurvey />
      <EventAdvert />

      {/* <BasicTabs /> */}

      {/* <LandingResCategory /> */}
      {/* <MembershipPricing /> */}
      {/* <LandingResCategory
        text="Explore 2,000+ Resources in 5 Key Categories for Employees of All
          Kinds"
      /> */}

      {/* <MembershipWidget /> */}

      {/* <LandingEvent /> */}
      <FollowMH />

      {/* FOOTER BEGINS */}
      <MHSubscribe />
      <MHFooter />

      {/* <StickyBottom /> */}
      {/* {timer === true ? <StickyLegal onClick={handleLegalClose} /> : null} */}
      {/* <SubscribeNewsDialog open={newsTimer} onClose={handleNewsClose} /> */}
      {/* <SubscribeDialog open={subTimer} onClose={handleSubClose} /> */}
      {/* {toolTimer === true ? <ToolkitPopUp onClick={handleToolClose} /> : null} */}
      {/* <ToolkitDialog open={toolTimer} onClose={handleToolClose} /> */}
    </React.Fragment>
  );
};

export default Index;
