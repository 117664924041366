import { Box, Grid, Typography } from "@mui/material";
import React from "react";

type Props = {
  headerText?: string;
};

const Clients = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="my-20 overflow-hidden">
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className=" py-6 uppercase text-center flex justify-center"
        >
          {props.headerText}
        </Typography>

        <Grid
          container
          spacing={2}
          className=" text-center lg:px-6 py-4 mx-auto place-content-center  items-center"
        >
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/benz.png"
              alt="summit"
              className=" w-56 h-20 md:h-32 object-contain grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/sc.png"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-32 object-contain grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/bournvita.png"
              alt="summit"
              className=" w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/maggi.png"
              alt="summit"
              className=" w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>

          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/FCMB-Logo.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/Jumia-Logo.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/access.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>

          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/ikeja.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/JAC-Elizade.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/eko-disco.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/august.png"
              alt="summit"
              className=" w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/fsdh.png"
              alt="summit"
              className=" w-56 h-20 md:h-24 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/green.png"
              alt="summit"
              className=" w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/krispy.png"
              alt="summit"
              className=" w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/crayon.png"
              alt="summit"
              className=" w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/bua.png"
              alt="summit"
              className=" w-56 h-20 md:h-24 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/baby.png"
              alt="summit"
              className=" w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/7up.jfif"
              alt="summit"
              className=" w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/viltreat.jpeg"
              alt="summit"
              className=" w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/saraWema.jpeg"
              alt="summit"
              className=" w-32 md:w-56 h-20 md:h-28 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/one.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/ten.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/mouton.jpg"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/stem-mets.jpeg"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>

          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/gm.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <img
              src="../../asset/sponsors/WholePurple.png"
              alt="summit"
              className="w-56 h-20 md:h-32 object-contain grayscale"
            />{" "}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Clients;
