import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

import MHPopper from "../Common/MenuPopper/MHPopper";
import MHBadge from "../Common/Badge/MHBadge";
import MHDropdown, { DropdownButton } from "../Common/Dropdown/MHDropdown";

import { ReactComponent as Heart2Icon } from "../../static/svg/brand/heart-2.svg";
import useDropdown from "../../hooks/use-dropdown";
import { TopLevelCategory } from "../../models/dashboard.model";
import AppContext from "../../store/context/app.context";
import { MHMenuItem } from "../Common/MenuPopper/MHMenu";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";

type ResProps = {
  index: number;
  value: number;
  thumbNailImageSrc?: string;
  title?: string;
  id?: number;
  slug?: string;
};

type Props = {
  title?: string | any;
  date?: string | any;
  caption?: string | any;
  slug?: string | any;
  imgSrc?: string | any;
  imageList?: string[] | any;
  createdAt?: string | any;
};

export const NavPrimaryLink = styled(Link)(({ theme }) => ({
  color: "#194049",
  lineHeight: "92%",
  textTransform: "capitalize",
  listStyle: "none",
  listStyleType: "none",
  display: "block",
  // letterSpacing: '0.44px',
  // [theme.breakpoints.down('sm')]: {
  //   display: 'none'
  // },
  // ...theme.typography.subtitle1,
  fontFamily: "Area-Normal-Bold !important",
  cursor: "pointer",
  textDecoration: "none",
  fontSize: ".75rem",
  "&:hover": {
    textDecoration: "none",
  },
}));

export const PopperLink = styled(NavPrimaryLink)(({ theme }) => ({
  fontSize: ".85rem",
  marginBlockEnd: 20,
  "& > sup": {
    color: theme.palette.primary.main,
    opacity: ".5",
    lineHeight: "0.1em",
  },
}));

const EmployersPopperContent = () => {
  const history = useHistory();

  const [resources, setResources] = useState([]);
  var resUrl = `${process.env.REACT_APP_RES_ARTICLE_URL}`;

  const [noOfElement, setnoOfElement] = useState(1);

  const resOne = resources.slice(0, noOfElement);
  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResources(jsonData.data);
      setResources(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
      console.log(resources);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  return (
    <Grid container p={4}>
      <Grid item xs>
        <Stack direction="row" spacing={10}>
          <Box>
            <Typography
              variant="subtitle2"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              fontSize=".65rem"
              mb={3}
              sx={{
                opacity: ".5",
              }}
            >
              Solutions
            </Typography>

            <PopperLink onClick={() => history.push("/wallet")}>
              Benefit Wallet
            </PopperLink>
            <PopperLink onClick={() => history.push("/savings")}>
              Emergency Savings
            </PopperLink>
            <PopperLink onClick={() => history.push("/employer/community")}>
              WorkLife Community
            </PopperLink>
            <PopperLink onClick={() => history.push("/employer/programs")}>
              Corporate Programs
            </PopperLink>
            <PopperLink onClick={() => history.push("/advisory")}>
              Advisory
            </PopperLink>
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              fontSize=".65rem"
              mb={3}
              sx={{
                opacity: ".5",
              }}
            >
              Explore by topic
            </Typography>

            <PopperLink>
              Career<sup> 7</sup>
            </PopperLink>
            <PopperLink>Caregiving</PopperLink>
            <PopperLink>Lifestyle</PopperLink>
            <PopperLink>Finances</PopperLink>
            <PopperLink>Wellbeing</PopperLink>
          </Box>
        </Stack>
      </Grid>

      <Divider orientation="vertical" flexItem>
        <Heart2Icon />
      </Divider>

      <Grid item xs>
        <Stack direction="row" spacing={3} pl={3}>
          <Box height="100%">
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1670481067/Group_3_vzxpi0.png"
              alt="popper-img"
            />
          </Box>
          <Box py={3}>
            <MHBadge
              content="New This Week"
              sx={{
                backgroundColor: "#EDF1D5",
                fontSize: ".9rem",
                mb: 2,
              }}
            />

            <Typography variant="subtitle2" fontSize="1.2rem" paragraph>
              What Black Working Moms Really Want in the Workplace
            </Typography>

            <Link>Read the Article</Link>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

const PopperContent = () => {
  const [resourcesPod, setResourcesPod] = React.useState<ResProps[]>([]);
  var podUrl = `${process.env.REACT_APP_RES_PODCAST_URL}`;

  const getPodResource = async () => {
    try {
      const response = await fetch(podUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesPod(jsonData.data);
      setResourcesPod(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  const [resourcesArt, setResourcesArt] = React.useState<ResProps[]>([]);
  var artUrl = `${process.env.REACT_APP_RES_ARTICLE_URL}`;

  const [noOfElement, setnoOfElement] = useState(1);

  const resOne = resourcesArt.slice(0, noOfElement);

  const getArtResource = async () => {
    try {
      const response = await fetch(artUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesArt(jsonData.data);
      setResourcesArt(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  const [resourcesVid, setResourcesVid] = React.useState<ResProps[]>([]);
  var videoUrl = `${process.env.REACT_APP_RES_VIDEO_URL}`;

  const getVidResource = async () => {
    try {
      const response = await fetch(videoUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesVid(jsonData.data);
      setResourcesVid(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  const [resourcesToolkits, setResourcesToolkits] = React.useState<ResProps[]>(
    []
  );
  var toolkitUrl = `${process.env.REACT_APP_RES_TOOLKIT_URL}`;

  const getToolkitResource = async () => {
    try {
      const response = await fetch(toolkitUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesToolkits(jsonData.data);
      setResourcesToolkits(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  const [resourcesEvent, setResourcesEvent] = React.useState<ResProps[]>([]);
  var eventUrl = `${process.env.REACT_APP_RES_EVENT_URL}`;
  const getEventResource = async () => {
    try {
      const response = await fetch(eventUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesEvent(jsonData.data);
      setResourcesEvent(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  const history = useHistory();

  React.useEffect(() => {
    getPodResource();
    getArtResource();
    getVidResource();
    getToolkitResource();
    getEventResource();
  }, []);

  const appCtx = React.useContext(AppContext);
  const { staticDataCacheMap } = appCtx;
  const topLvlCategories: TopLevelCategory[] = React.useMemo(
    () => staticDataCacheMap.get("topLevelCategories") || [],
    [staticDataCacheMap]
  );

  return (
    <Grid container p={4}>
      <Grid item xs>
        <Stack direction="row" spacing={10}>
          <Box>
            <Typography
              variant="subtitle2"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              fontSize=".65rem"
              mb={3}
              sx={{
                opacity: ".5",
              }}
            >
              Resources
            </Typography>

            <PopperLink onClick={() => history.push("/resources/toolkits")}>
              Toolkits<sup> {resourcesToolkits.length}</sup>
            </PopperLink>
            <PopperLink onClick={() => history.push("/resources/articles")}>
              Articles<sup> {resourcesArt.length}</sup>
            </PopperLink>
            <PopperLink onClick={() => history.push("/resources/podcasts")}>
              Podcasts<sup> {resourcesPod.length}</sup>
            </PopperLink>
            <PopperLink onClick={() => history.push("/resources/videos")}>
              Videos<sup> {resourcesVid.length}</sup>
            </PopperLink>
            <PopperLink onClick={() => history.push("/resources/events")}>
              Events<sup> {resourcesEvent.length}</sup>
            </PopperLink>
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              fontSize=".65rem"
              mb={3}
              sx={{
                opacity: ".5",
              }}
            >
              Explore by topic
            </Typography>

            {/* <sup> 7</sup> */}

            {topLvlCategories.map((category) => (
              <PopperLink>{category.sectionName}</PopperLink>
            ))}
          </Box>
        </Stack>
      </Grid>

      <Divider orientation="vertical" flexItem>
        <Heart2Icon />
      </Divider>

      <Grid item xs>
        <Stack direction="row" spacing={3} pl={3}>
          {resOne.map((resData, index) => (
            <>
              <Box height="100%">
                <img
                  // src={resData.thumbNailImageSrc}
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1670481067/Group_3_vzxpi0.png"
                  alt="popper-img"
                  className="w-[250px] h-[250px] "
                />
              </Box>
              <Box py={3} className=" w-[275px]">
                <MHBadge
                  content="New This Week"
                  sx={{
                    backgroundColor: "#EDF1D5",
                    fontSize: ".9rem",
                    mb: 2,
                  }}
                />

                <Typography
                  variant="subtitle2"
                  fontSize="1.2rem"
                  paragraph
                  className="w-[275px] line-clamp-3"
                >
                  {resData.title}
                </Typography>
                <NavPrimaryLink
                  onClick={() =>
                    history.push(
                      `/resources/articles/${resData.slug}/${resData.id}`
                    )
                  }
                >
                  <Typography
                    variant="subtitle2"
                    fontSize="12px"
                    lineHeight="102%"
                    letterSpacing="0.05em"
                    paragraph
                  >
                    Read the Article
                  </Typography>
                </NavPrimaryLink>
              </Box>
            </>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

const NavPrimaryLinks = () => {
  const [dropdownAnchorEl, setDropdownAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const handleOpenDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDropdownAnchorEl(e.currentTarget);
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setDropdownAnchorEl(null);
    setIsDropdownOpen(false);
  };

  const {
    isDropdownOpen: isEmployersDropdownOpen,
    dropdownAnchorEl: employersDropdownAnchorEl,
    handleOpenDropdown: handleOpenEmployersDropdown,
    handleCloseDropdown: handleCloseEmployersDropdown,
  } = useDropdown();

  const history = useHistory();

  React.useEffect(() => {
    // close sidebar when route changes
    history.listen((location, action) => {
      // console.log(location);
      // console.log(action);
      handleCloseDropdown();
    });
  }, []);

  const [summitData, setSummitData] = useState<Props[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db, "caf-past-summit"); // Replace with your actual collection name
        const querySnapshot = await getDocs(
          query(collectionRef, orderBy("slug", "asc"))
        );

        const data = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as Props),
        }));

        setSummitData(data);
        console.log(data, "Data");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const AreaOfFocus: MHMenuItem[] = [
    {
      label: "Childcare",
      onClick: () => history.push("/childcare"),
    },
    {
      label: "Aging Care",
      onClick: () => history.push("/aging-care"),
    },
    // {
    //   label: "Disability Care",
    //   onClick: () => history.push("/disability-care"),
    // },
    {
      label: "Health Care",
      onClick: () => history.push("/"),
    },
    {
      label: `Employee Care`,
      onClick: () => history.push("/"),
    },
    {
      label: `Climate Care`,
      onClick: () => history.push("/"),
    },
    {
      label: `Care Work(ers)`,
      onClick: () => history.push("/"),
    },
    {
      label: `Care Economy`,
      onClick: () => history.push("/"),
    },
  ];

  const SUMMIT_YEAR: MHMenuItem[] = [
    // Upcoming Summit page
    // {
    //   label: "2024",
    //   onClick: () => history.push("/summit"),
    // },
    ...summitData.map((event, item) => ({
      label: event.date?.toString().slice(-4), // Use optional chaining to handle possible undefined
      onClick: () =>
        history.push(
          `/recap-summit/${event.date?.toString().slice(-4)}/${event.slug}`
        ),
    })),
  ];

  // Sort the array by the 'label' property in descending order
  SUMMIT_YEAR.sort((a, b) => {
    if (a.label && b.label) {
      return parseInt(b.label) - parseInt(a.label);
    }
    return 0;
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      pl={3}
      pr={2}
      display={{ xs: "flex", md: "flex" }}
    >
      {/* <MHPopper
        open={isEmployersDropdownOpen}
        anchorEl={employersDropdownAnchorEl}
        popperContent={<EmployersPopperContent />}
        onClickAway={handleCloseEmployersDropdown}
        sx={{
          width: '100vw',
          borderRadius: 0,
          borderTop: '1px solid #F3F4F6'
        }}>
        <DropdownButton
          label={'Solutions'}
          onExpand={handleOpenEmployersDropdown}
          isOpen={isEmployersDropdownOpen}
        />
      </MHPopper> */}
      <NavPrimaryLink onClick={() => history.push("/about")}>
        About
      </NavPrimaryLink>

      <MHDropdown
        label="Focus Area"
        items={AreaOfFocus}
        menuContainerSx={{
          width: 200,
          overflowX: "hidden",
          textOverflow: "ellipsis",
          ml: 0,
          "& .MuiMenuItem-root": {
            fontSize: ".75rem",
            color: "#194049",
            textTransform: "",
            fontFamily: "Area-Normal-Bold !important",
          },
        }}
        dropdownButtonSx={{
          "& .MuiTypography-root": {
            fontSize: ".75rem",
            color: "#194049",
            textTransform: "capitalize",
            fontFamily: "Area-Normal-Bold !important",
          },
        }}
      />

      <MHDropdown
        label="Summit"
        items={SUMMIT_YEAR}
        menuContainerSx={{
          width: 200,
          overflowX: "hidden",
          textOverflow: "ellipsis",
          ml: 0,
          "& .MuiMenuItem-root": {
            fontSize: ".75rem",
            color: "#194049",
            textTransform: "capitalize",
            fontFamily: "Area-Normal-Bold !important",
          },
        }}
        dropdownButtonSx={{
          "& .MuiTypography-root": {
            fontSize: ".75rem",
            color: "#194049",
            textTransform: "capitalize",
            fontFamily: "Area-Normal-Bold !important",
          },
        }}
      />

      {/* <NavPrimaryLink onClick={() => history.push("/leave-gap")}>
        Care Coverage
      </NavPrimaryLink>
      <NavPrimaryLink onClick={() => history.push("/individuals")}>
        For You
      </NavPrimaryLink>
      <NavPrimaryLink onClick={() => history.push("/employers")}>
        For Employers{" "}
      </NavPrimaryLink> */}

      {/* <MHPopper
        open={isDropdownOpen}
        anchorEl={dropdownAnchorEl}
        popperContent={<PopperContent />}
        onClickAway={handleCloseDropdown}
        sx={{
          width: "100vw",
          borderRadius: 0,
          borderTop: "1px solid #F3F4F6",
        }}
      >
        <DropdownButton
          label={"Resources"}
          onExpand={handleOpenDropdown}
          isOpen={isDropdownOpen}
          typographySx={{
            color: "#194049",
            textTransform: "capitalize",
            fontFamily: "Area-Normal-Bold !important",
          }}
        />
      </MHPopper> */}
      {/* <NavPrimaryLink href="https://www.newsletter.mhworklife.com" target="_blank">
        Newsletter
      </NavPrimaryLink> */}
      {/* <NavPrimaryLink onClick={() => history.push('/employers')}>Employers</NavPrimaryLink> */}
    </Stack>
  );
};

export default NavPrimaryLinks;
