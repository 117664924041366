import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";

import { ReactComponent as LeftBtn } from "../../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../../static/svg/right-btn.svg";

type Props = {
  text?: string;
  author?: string;
};

type DataProps = {
  data?: Props[];
};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute my-6 top-[100%] right-[30%] md:top-[45%] z-10 md:right-1 lg:right-10">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute my-6 top-[100%] left-[30%] md:top-[45%] z-10 md:left-1 lg:left-10">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const YellowSlider = (props: DataProps) => {
  const [slideIndex, setSlideIndex] = React.useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2500,
    autoplay: true,
    autoplaySpeed: 5200,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <React.Fragment>
      <Box
        className="summit-stats-component h-[610px] md:h-[450px] lg:h-[450px] "
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Box className="mx-auto">
          <Slider
            {...settings}
            beforeChange={(current, next) => setSlideIndex(next)}
          >
            {props.data!.map((gallery, index) => (
              <Box
                key={index}
                className="md:pt-20 text-center items-center !justify-center"
              >
                <Typography
                  variant="body1"
                  fontSize={{ xs: "16px", sm: "18px" }}
                  fontWeight={700}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" py-10 w-[77%] lg:w-[65%] !mx-auto"
                >
                  {gallery.text}
                </Typography>

                <Typography
                  variant="caption"
                  fontSize={{ xs: "12px", sm: "12px" }}
                  fontWeight={900}
                  lineHeight={{ xs: "20px", sm: "12px" }}
                  letterSpacing={"0.1em"}
                  className="pb-8 uppercase w-[65%]"
                >
                  {gallery.author}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default YellowSlider;
