import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MHButton from "../Common/Button/MHButton";
import ParentsDialog from "./ParentsDialog";
import LeadersDialog from "./LeadersDialog";
import InvestorsDialog from "./InvestorsDialog";

type Props = {};

const SummitAttendee = (props: Props) => {
  const [openParents, setOpenParents] = React.useState(false);
  const [openLeaders, setOpenLeaders] = React.useState(false);
  const [openInvestors, setOpenInvestors] = React.useState(false);

  const handleOpenParents = () => {
    setOpenParents(true);
  };

  const handleCloseParents = () => {
    setOpenParents(false);
  };
  const handleOpenLeaders = () => {
    setOpenLeaders(true);
  };

  const handleCloseLeaders = () => {
    setOpenLeaders(false);
  };
  const handleOpenInvestors = () => {
    setOpenInvestors(true);
  };

  const handleCloseInvestors = () => {
    setOpenInvestors(false);
  };

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  return (
    <Fragment>
      <section id="attendees" className="pt-8 md:pt-10">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "30px", sm: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={"-0.06em"}
          className="mx-auto text-center mt-10"
        >
          Who should attend?
        </Typography>

        <Box className=" lg:flex gap-0 lg:px-14 place-content-center">
          <Box className="md:w-[401px] mx-auto mt-10">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caw-men.jpg"
              alt="Image"
              style={{}}
              className="object-contain rounded-md h-[335px] w-[335px] mx-auto md:h-[401px] md:w-[401px]"
            />

            <Accordion className="shadow-none ">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="w-fit mx-auto"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "16px" }}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto mr-2"
                >
                  Employers And Business Leaders
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!mx-auto !place-content-center">
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto py-6 w-[300px] md:w-[374px]  lg:h-[400px]"
                >
                  As executives and decision-makers, you are the visionaries
                  steering the course of workplace transformation. This summit
                  is tailored for leaders like you, recognizing that you hold
                  the key to prioritizing employee care. Delve into insights on
                  strategic advantages, including Parental leave policies,
                  Childcare, Disability Care, Adult/Aging Care, Mobility, and
                  Health Care. Learn best practices that not only enhance
                  employee well-being but also propel your organization toward
                  unprecedented success. Your attendance is not just an
                  investment in your workforce; it's a strategic move that can
                  reshape the future of your organization.
                  <br />
                  <br />
                  {/* Your company can support your registration to the conference. */}
                  {/* <a
                    // href="CAW-Employee-Reimbursement.docx"
                    className="underline pl-1 cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://mh-resources-production.s3.us-west-1.amazonaws.com/Care+At+Work+Reimbursement+Form+(1).pdf"
                      );
                    }}
                  >
                    Download reimbursement template
                  </a> */}
                </Typography>

                {/* <Box
                  className="relative items-center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MHButton
                    onClick={() => handleOpenParents()}
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontSize: "12px",
                    }}
                  >
                    Learn more here
                  </MHButton>
                </Box> */}
              </AccordionDetails>
            </Accordion>

            <Box className="bg-gray-300 h-[1px] w-[91.5%] my-7 mx-auto"></Box>
          </Box>
          <Box className=" md:w-[401px] mx-auto mt-10">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Attendees-1abq.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md h-[335px] w-[335px] mx-auto md:h-[401px] md:w-[401px]"
            />

            <Accordion className="shadow-none">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className="w-fit mx-auto"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "16px" }}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto mr-2"
                >
                  Policy Makers And Legislators
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!mx-auto !place-content-center">
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto py-6 w-[300px] md:w-[374px]  lg:h-[400px]"
                >
                  As policy makers and legislators, your influence extends
                  beyond governance. Join us to explore the critical role of
                  policy in fostering a culture of care within the workforce and
                  society. Gain insights into the necessity of supportive
                  policies, contributing to employee satisfaction, increased
                  women participation in the economic and political landscape,
                  and driving national economic growth. Your attendance is a
                  call to action for a more caring, inclusive, and prosperous
                  future. Join us in shaping policies that redefine employee
                  care and societal well-being in Nigeria
                </Typography>
                {/* <Box
                  className="relative items-center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MHButton
                    onClick={() => handleOpenLeaders()}
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontSize: "12px",
                    }}
                  >
                    Learn more
                  </MHButton>
                </Box> */}
              </AccordionDetails>
            </Accordion>
            <Box className="bg-gray-300 h-[1px] w-[91.5%] my-7 mx-auto"></Box>
          </Box>
          <Box className="md:w-[401px] mx-auto mt-10">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Attendees+3.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md h-[335px] w-[335px] mx-auto md:h-[401px] md:w-[401px]"
            />

            <Accordion className="shadow-none ">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                className="w-fit mx-auto"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "16px" }}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto mr-2"
                >
                  International Organizations And NGO
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!mx-auto !place-content-center">
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto w-[300px] md:w-[374px]  lg:h-[400px]"
                >
                  Representatives of International Organizations and NGOs, your
                  global perspective and expertise are vital in reshaping
                  Nigeria's approach to employee care. This summit offers a
                  dedicated platform for collaboration, where your contributions
                  can make a meaningful impact. Join us as we work together to
                  elevate the importance of care in Nigerian communities and
                  workplaces. Your attendance is an opportunity to be part of
                  initiatives that drive positive change.
                </Typography>
                {/* <Box
                  className="relative items-center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MHButton
                    onClick={() => handleOpenInvestors()}
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontSize: "12px",
                    }}
                  >
                    Partner with us
                  </MHButton>
                </Box> */}
              </AccordionDetails>
            </Accordion>
            <Box className="bg-gray-300 h-[1px] w-[91.5%] mx-auto"></Box>
          </Box>
        </Box>
      </section>
      <ParentsDialog open={openParents} onClose={handleCloseParents} />
      <LeadersDialog open={openLeaders} onClose={handleCloseLeaders} />
      <InvestorsDialog open={openInvestors} onClose={handleCloseInvestors} />
    </Fragment>
  );
};

export default SummitAttendee;
