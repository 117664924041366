import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FaqContactHeader from "../components/Common/Header/FaqContactHeader";
import FAQSideBar from "../components/FAQ/FAQSideBar";
import FAQBody from "../components/FAQ/FAQBody";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHFooter from "../components/Layout/MHFooter";
import FAQMobileSideBar from "../components/FAQ/FAQMobileSideBar";

type Props = {};

const FAQ = (props: Props) => {
  return (
    <React.Fragment>
      <FaqContactHeader
        label=" Find your answers"
        title=" Frequently Asked Questions "
        textColor="white"
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ_ulvkpc.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ-mob_kcwqmd.png"
        // Center Class attributes
        headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[331px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />

      <Grid container spacing={1} className="pl-8 hider md:pl-16 mt-12 gap-1">
        <Grid item xs={12} sm={3} md={3} lg={2.75} className=" ">
          <FAQSideBar />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={7} className="">
          <FAQBody />
        </Grid>
      </Grid>

      {/* FOOTER BEGINS */}
      <MHSubscribe />
      <MHFooter />
    </React.Fragment>
  );
};

export default FAQ;
