import { SelectOption } from '@mui/base';

export const LANDING_QUESTIONS_OPTIONS = [
    {
      label: 'How MH can amplify my employees daily work and personal life',
      value: 'How MH can amplify my employees daily work and personal life'
    },
    {
      label: 'How can MH help me start fresh',
      value: 'How can MH help me start fresh'
    },
    {
      label: 'How can MH help me flourish at work',
      value: 'How can MH help me flourish at work'
    }
  ];

  export const CARE_SUMMIT_SPEAKERS_DATA = [
    
    {
      name: 'Blessing Adesiyan',
      company: 'MH WorkLife',
      title: 'Founder & CEO',
      img: 'https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png'
    },
    {
      name: 'Abbey Carlton',
      company: `Social Impact, Indeed`,
      title: 'Vice President',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Abbey+headshot.jpeg'
    },
   
    {
      name: 'Donald Knight',
      company: `Greenhouse`,
      title: 'Chief People Officer',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Donald.jpg'
    }, 
    {
      name: 'Sascha Mayer',
      company: 'Mamava',
      title: 'Co-Founder & CXO',
      img: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117594/Flourish/SaschaMayer_lgpoak_xb0rna.png'
    },
    {
      name: 'Lauren Dai',
      company: `Cocoon`,
      title: 'Co-founder and COO',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/LaurenDai.jpeg'
    },
    {
      name: 'Siran Cao',
      company: `Hey Mirza`,
      title: 'CEO & Co-Founder',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Siran+Cao.jpg'
    },
    {
      name: 'Sarah Olin',
      company: `LUMO`,
      title: 'Founder & CEO',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Sarah+Olin.jpg'
    },
    {
      name: 'Lauren Perrotta',
      company: 'Vivvi',
      title: 'VP, Employer Partnerships',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Lauren+Perrotta+.png'
    },
    {
      name: 'Fatima Goss Graves',
      company: `National Women's Law Center`,
      title: 'President & CEO',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Fatima.jpg'
    },
    {
      name: 'Paul Saiedi',
      company: 'Uber',
      title: 'Director of DE & I',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Paul-Nezaum-Saiedi-headshot.webp'
    },
    // {
    //   name: 'Stephanie LeBlanc-Godfrey',
    //   company: 'Google',
    //   title: 'Head Of Inclusion',
    //   img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/steph.jpg'
    // },
    {
      name: 'Kristin Rowe-Finkbaker',
      company: `Moms Rising`,
      title: 'Executive Director',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/KristinRowe.png'
    },
    {
      name: 'Jocelyn Frye',
      company: `National Partnership for Women and Families`,
      title: 'President',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Jocelyn.jpg'
    },
    {
      name: 'Dinah Alobeid',
      company: `Greenhouse`,
      title: 'Senior Director of Content and Communications',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Dinah.jpg'
    },
    {
      name: 'Paul Sullivan',
      company: `The Company of Dads`,
      title: 'Founder',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Paul+Sullivan.jpg'
    },
  
   
    {
      name: 'Neha Ruch',
      company: `Mother Untitled`,
      title: 'Founder',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Neha.jpeg'
    },
   
    {
      name: 'Anna Steffeney',
      company: 'FamTech.Org',
      title: 'Executive Director',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Anna.jpg'
    },
    {
      name: 'Katherine Goldstein',
      company: 'Better Life Lab at New America',
      title: 'Care Reporting Fellow',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/KatherineGoldstein.jpg'
    },
    {
      name: 'Rebecca Gale',
      company: 'Better Life Lab at New America',
      title: 'Reporting Fellow',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/RGale+Headshot.jpg'
    },
    {
      name: 'Randi Braun',
      company: 'Something Major',
      title: 'Author & CEO',
      img: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Randi.jpeg'
    },
  
    
  ]

  export const Past_Speaker = [
    {
      id: 1,
      name: "Blessing Adesiyan",
      title: "CEO & Founder MotherHonestly",
      img: "../../asset/bbs.png",
      instagram: "https://instagram.com/motherhonestly?igshid=YmMyMTA2M2Y=",
      linkedin: "https://www.linkedin.com/in/blessingadesiyan",
      twitter:
        "https://twitter.com/mother_honestly?s=11&t=EPkdzjQl7ATuRXMjxH4UzQ",
      facebook: "",
    },
    {
      id: 2,
      name: "Yetty Williams",
      title: "Founder & CEO, LagosMums",
      img: "../../asset/yet.png",
      instagram: "https://instagram.com/lagosmums?igshid=YmMyMTA2M2Y=",
      linkedin: "https://www.linkedin.com/in/yettywilliams",
      twitter: "https://twitter.com/lagosmums?s=21&t=kSsq8wV2-zSr6BLmadcyZw",
      facebook: "",
    },
    {
      id: 3,
      name: "Ife Durosinmi-Etti",
      title: "CEO Herconomy",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1660723672/ife_lvigxn.png",
      instagram: "https://instagram.com/ifedurosinmietti?igshid=YmMyMTA2M2Y=",
      linkedin:
        "https://ng.linkedin.com/in/ife-durosinmi-etti-mba-63b24141?trk=public_post_share-update_actor-image",
      twitter: "",
      facebook: "",
    },
    {
      id: 4,
      name: "Adebola Williams",
      title: "Chairman AW Network",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1660677700/debola_yqw6pp.png",
      instagram: "https://www.instagram.com/debolalagos/feed/?hl=en",
      linkedin: "https://ng.linkedin.com/in/adebola-williams-50722613",
      twitter: "https://twitter.com/debolalagos?lang=en",
      facebook: "",
    },

    {
      id: 31,
      name: "Dayo Aderugbo",
      title:
        "Head, Corporate Affairs, Brand & Marketing, Nigeria & West Africa, Standard Chatered Bank",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/dayo+Background+Removed.png",
      instagram: "",
      linkedin: "",
      twitter: "",
      facebook: "",
    },
    {
      id: 27,
      name: "Olusegun Adeniyi",
      title: "Chief Digital Officer, Wema Bank",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Adeniyi+Background+Removed.png",
      instagram: "https://www.instagram.com/segun_a_adeniyi    ",
      linkedin: "",
      twitter: "https://www.twitter.com/segun_a_adeniyi",
      facebook: "   ",
    },
    {
      id: 32,
      name: "	Ifeanyi Orabuche ",
      title: "Category & Marketing Manager, Golden Morn",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/ifeanyi+Background+Removed.png",
      instagram: "",
      linkedin:
        "https://ng.linkedin.com/in/ifeanyichukwu-onubogu-orabuche-mba-a1bb7a35?trk=public_post_share-update_actor-text",
      twitter: "",
      facebook: "https://m.facebook.com/onubogu.ifeanyichukwu",
    },

    {
      id: 24,
      name: "Nankling Danfulani",
      title: "Trade Adviser, British Deputy High Commission",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/Nankling+Background+Removed.png",
      instagram: "https://www.instagram.com/nankkling/?hl=en",
      linkedin: "https://ng.linkedin.com/in/nankling-carol-danfulani-",
      twitter: "https://twitter.com/taaluko?lang=en",
      facebook: "",
    },

    {
      id: 21,
      name: "Abiola Seriki-Ayeni",
      title: "DG, Office of Education Quality Assurance ",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/abiola+Background+Removed.png",
      instagram: "https://www.instagram.com/dgoeqa/?hl=en",
      linkedin: "",
      twitter: "https://twitter.com/dgoeqa?lang=en",
      facebook: "https://m.facebook.com/DGOEQA",
    },
    

    {
      id: 11,
      name: "Adaora Mbelu",
      title: "Brand Consultant and Chief Storyteller",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1662455861/adaora_ggcbdm.png",
      instagram: "https://www.instagram.com/adaora.lumina/?hl=en",
      linkedin: "https://www.linkedin.com/in/adaorambelu/",
      twitter: "https://twitter.com/adaoralumina",
      facebook: "",
    },

    {
      id: 20,
      name: "Mary Aderinola Ojulari",
      title:
        "Managing Director at Weststar Asssociates Limited (Mercedes-Benz Nigeria)",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/mary+Background+Removed.png",
      instagram: "https://www.instagram.com/adepeggy/?hl=en",
      linkedin: "",
      twitter: "",
      facebook: "https://m.facebook.com/MaryAderinola",
    },
   
    {
      id: 22,
      name: "Wale Anifowose",
      title: "GM of Programs and Partnership EDC",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/wale+Background+Removed.png",
      instagram: "",
      linkedin: "https://ng.linkedin.com/in/olawaleanifowose",
      twitter: "",
      facebook: "https://m.facebook.com/wale.anifowose.94",
    },
   
    {
      id: 13,
      name: "Bunmi George ",
      title: "Founder, Shredder Gang    ",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1662636344/bunmi_plqupd.png",
      instagram: "https://www.instagram.com/jbums/?hl=en",
      linkedin: "",
      twitter: "",
      facebook: "",
    },

    {
      id: 18,
      name: "Hamisha Daryani Ahuja",
      title:
        "Film Producer, Director, Entrepreneur, Motivational Speaker, and Actress ",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1665387466/hamishaa_oc4aky.png",
      instagram: "",
      linkedin: "",
      twitter: "",
      facebook: "",
    },
    {
      id: 33,
      name: "	Natasha Mahtani ",
      title: "Relationship & Divorce Coach ",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1665581615/natasha_ftgzub.png",
      instagram: "",
      linkedin: "",
      twitter: "",
      facebook: "",
    },
    {
      id: 34,
      name: "Joe Khalifeh ",
      title: "Gyneacologist and Obsteric Consultant  ",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1665581616/joe_ztkmvj.png",
      instagram: "https://www.instagram.com/drjoekhalife/?hl=en",
      linkedin:
        "https://www.linkedin.com/in/joe-khalife-a0877270?originalSubdomain=lb",
      twitter: "",
      facebook: "https://m.facebook.com/drjoekhalife/",
    },
    {
      id: 12,
      name: "Joycee Awosika",
      title: "Founder and CEO of ORIKI",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1662455861/joyce_rhljh0.png",
      instagram: "https://www.instagram.com/soarwithjoycee",
      linkedin: "https://ng.linkedin.com/in/joyceeawosika",
      twitter: "",
      facebook: "",
    },
    {
      id: 6,
      name: "Dr. Maymunah Yusuf Kadiri ",
      title: "Head of Pinnacle Health",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1660723404/may_tcn9e6.png",
      instagram: "https://www.instagram.com/iamdrmay/?hl=en",
      linkedin: "https://ng.linkedin.com/in/maymunah-kadiri-0012511b2",
      twitter: "https://twitter.com/iamdrmaymunah?s=21",
      facebook: "https://www.facebook.com/officialdrmaymunah",
    },
    {
      id: 14,
      name: "Arese 'Smart Money'  Ugwu",
      title: "Author and TV Producer, The Smart Money Woman",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1662636343/arese_bqkpws.png",
      instagram: "https://www.instagram.com/smartmoneyarese/?hl=en",
      linkedin: "https://www.linkedin.com/in/arese-ugwu-272710a7/",
      twitter: "https://twitter.com/smartmoneyarese",
      facebook: "",
    },
    {
      id: 15,
      name: "Yeside Olayinka-Agbola    ",
      title: "Founder, Olori Coitus",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1662636346/yeside_gmhyvy.png",
      instagram: "https://www.instagram.com/oloricoitus/?hl=en",
      linkedin: "https://www.linkedin.com/in/yeside/",
      twitter: "https://twitter.com/oloricoitus?lang=en",
      facebook: "",
    },
    {
      id: 16,
      name: "Tosin Olaseinde",
      title: "Founder and CEO, Money Africa & Ladda     ",
      img: "https://res.cloudinary.com/mother-honestly/image/upload/v1662636343/tosin_ctjqs3.png",
      instagram: "https://www.instagram.com/tosinolaseinde/?hl=en",
      linkedin: "https://www.linkedin.com/in/tosinmoneyafrica/",
      twitter: "https://twitter.com/tosinolaseinde",
      facebook: "",
    },



    {
      id: 5,
      name: "Adeola Kingsley-James",
      title: "Life-giver and Kingmaker ",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/adeola+Background+Removed.png",
      instagram: "https://www.instagram.com/diyolakingsleyjames/",
      linkedin: "https://ng.linkedin.com/in/adeola-kingsley-james-309214211",
      twitter: "",
      facebook: "",
    },
    {
      id: 7,
      name: "Adenike Oyetunde-Lawal",
      title: "Author/Disability and Inclusion Advocate    ",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/adenike+Background+Removed.png",
      instagram: "https://www.instagram.com/adenikeoyetunde/?hl=en",
      linkedin:
        "https://ng.linkedin.com/in/adenike-oyetunde-lawal-45586118?trk=org-employees",
      twitter: "https://twitter.com/denikeoyetunde",
      facebook: "",
    },
    {
      id: 8,
      name: "Temi Olajide",
      title: "Founder @Mummyclinicc",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/temi+Background+Removed.png",
      instagram: "https://www.instagram.com/mummyclinicc/?hl=en",
      linkedin: "https://uk.linkedin.com/in/temiolajide",
      twitter: "",
      facebook: "",
    },
   
    {
      id: 10,
      name: "Oreoluwa Lesi",
      title: "Founder, Women’s Technology Empowerment Centre (W.TEC)    ",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/oreoluwa+Background+Removed.png",
      instagram: "https://www.instagram.com/msoreoluwa/?hl=en",
      linkedin: "https://ng.linkedin.com/in/oreoluwa-somolu-lesi-546b441",
      twitter: "https://twitter.com/oreoluwa?lang=en",
      facebook: "",
    },
   
    {
      id: 17,
      name: "Bolanle Ibitola",
      title: "Managing Director & Chief Executive Officer of Crayon Development",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/bolanle+Background+Removed.png",
      instagram: "  ",
      linkedin: "",
      twitter: "",
      facebook: "   ",
    },
    
    {
      id: 19,
      name: "Excel Adeleye Samuel",
      title: "Co-founder of Wallmark Counselling",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/excel+Background+Removed.png",
      instagram: "https://www.instagram.com/excelimpacts/?hl=en",
      linkedin:
        "https://ng.linkedin.com/in/excel-adeleye-samuel-chmc-phd-hr-2b427a25?trk=public_profile_samename-profile",
      twitter: "https://twitter.com/tosinolaseinde",
      facebook: "",
    },
    
    {
      id: 23,
      name: "Tomilayo Aluko",
      title:
        "Head of Corporate Communications and Marketing, Central Securities Clearing Systems",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/aluko+Background+Removed.png",
      instagram: "https://www.instagram.com/tomilayoaluko/?hl=en",
      linkedin: "https://ng.linkedin.com/in/tomilayo-aluko-a4b0a31a",
      twitter: "",
      facebook: "",
    },
    
    {
      id: 25,
      name: "Fona Williams",
      title:
        "Manager, Global Diagnostic Team at Clinton Health Access Initiative",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/fona+Background+Removed.png",
      instagram: "",
      linkedin: "https://ng.linkedin.com/in/fosunloye",
      twitter: "",
      facebook: "",
    },
    {
      id: 26,
      name: "Adaorie Udechukwu",
      title: "Senior Gender Advisor at International Finance Corporation, Africa",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/adaore+Background+Removed.png",
      instagram: "https://www.instagram.com/adaorieudechukwu ",
      linkedin: "https://www.linkedin.com/in/adaorieudechukwu/    ",
      twitter: "",
      facebook: "   ",
    },
    
  
    {
      id: 28,
      name: "Tayo Opatayo",
      title: "HR Expert | Leadership Coach",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/opatayo+Background+Removed.png",
      instagram: "https://www.instagram.com/tayoopatayo/?hl=en",
      linkedin:
        "https://ng.linkedin.com/in/tayoopatayo?trk=public_profile_browsemap",
      twitter: "",
      facebook: "   ",
    },

    {
      id: 30,
      name: "Peter Obaseki",
      title: "Partner McKinsey",
      img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/peter+Background+Removed.png",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/peter-gaius-obaseki-3662254",
      twitter: "",
      facebook: "",
    },
    // {
    //   id: 29,
    //   name: "Dinma Nwobi",
    //   title: "Founder, Happify Humanity Project",
    //   img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/dinma+Background+Removed.png",
    //   instagram: "  ",
    //   linkedin: "",
    //   twitter: "",
    //   facebook: "   ",
    // },

    // {
    //   id: 9,
    //   name: "Chinasa Trinitta Amadi",
    //   title: "Medical Doctor MPH, DipIBLM, FAIPH",
    //   img: "https://mh-resources-production.s3.us-west-1.amazonaws.com/chinasa+Background+Removed.png",
    //   instagram: "https://www.instagram.com/ariellahealthandfitness/?hl=en",
    //   linkedin: "https://www.linkedin.com/in/yettywilliams",
    //   twitter: "",
    //   facebook: "",
    // },
    
  
   
    
  ];

  export const SPEAKERS_DATA = [
    
    {
      name: 'Blessing Adesiyan',
      company: 'MH WorkLife',
      title: 'Founder & CEO',
      img: 'https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png'
    },
    
    {
      name: 'Chris Hyams',
      company: 'Indeed',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680271558/speakers/Chris_Hyams_bb4pkx.jpg'
    },
    {
      name: 'Brian Little',
      company: 'Intel',
      title: 'VP, Human Resources',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680271941/speakers/BrianLittle_kp0pdj.png'
    },
    {
      name: 'Christine M. Carter',
      company: 'Lexia Learning',
      title: 'Content Director',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117576/Flourish/ChristineMichelCarter_rrghke_jlr2uz.png'
    },
    {
      name: 'Gayatri Agnew',
      company: 'Walmart',
      title: 'Senior Director',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680002450/speakers/Gayatri_Agnew_azhaic.jpg'
    },
    {
      name: 'Dr. C Nicole Mason',
      company: 'IWPR',
      title: 'President & CEO | NonProfit Leader',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117591/Flourish/NicoleMason_kk5rsq_qaj7o9.png'
    },
    {
      name: 'Eve Rodsky',
      company: 'Fair Play Life',
      title: 'Author & Economist',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117575/Flourish/EveRodsky_f8x3ai_b26cge.png'
    },
    {
      name: 'Jack Mardock',
      company: 'Oyster',
      title: 'Co-Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680635876/speakers/jack_wqny1k.jpg'
    },
    {
      name: 'Kacy Flemming',
      company: 'Global Well-Being',
      title: 'Head',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680635876/speakers/kacy_s0xqym.jpg'
    },
    {
      name: 'Sascha Mayer',
      company: 'Mamava',
      title: 'Co-Founder & CXO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117594/Flourish/SaschaMayer_lgpoak_xb0rna.png'
    },
    {
      name: 'Reshma Saujani',
      company: 'Girls Who Code + Moms First US',
      title: 'Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688023007/speakers/reshma_t8xe9o.jpg'
    },
    {
      name: 'Natalie Mayslich',
      company: 'Care.com',
      title: 'President',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117595/Flourish/NatalieMayslich_hrdtvk_uerkci.png'
    },
    {
      name: 'Nicole Centeno',
      company: 'Splendid Spoon',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117595/Flourish/NicoleCenteno_nwffed_bxnhfi.png'
    },
    {
      name: 'Sarah Dorsett',
      company: 'Nanit',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117588/Flourish/SarahDorsett_vilopt_dr7myh.png'
    },
    {
      name: 'Sarah Hardy',
      company: 'Bobbie',
      title: 'Co-Founder & COO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117595/Flourish/SarahHardy_slrl4p_wwjv6y.png'
    },
    {
      name: 'Adeline Azrack',
      company: 'Fondation Chanel',
      title: 'Managing Director',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117558/Flourish/AdelineAzrack_fyferr_gyeadb.png'
    },
    {
      name: 'Audrey G. Kingo',
      company: 'MH WorkLife',
      title: 'Editor-In-Chief',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666286155/Flourish/AudreyGoodsonKingo_p9vuwk.png'
    },
    {
      name: 'Charles Bonello',
      company: 'Vivvi',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666289958/Flourish/CharlesBonello_qnruyc.png'
    },
    {
      name: 'Stephanie LeBlanc-Godfrey',
      company: 'Google',
      title: 'Head Of Inclusion',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688132563/speakers/stephanie_leblan_f3xhm2.png'
    },
    {
      name: 'Abbey Carlton',
      company: 'Indeed',
      title: 'VP, Social Impact',
      imgSrc: 'https://res.cloudinary.com/taye-motherhonestly/image/upload/v1666117569/Flourish/AbbeyCarlton_ygaxae_lffvag.png'
    },
    {
      name: 'Bolanle Williams-Olley',
      company: 'Mancini Duffy',
      title: 'CFO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688024415/speakers/bolanle_mwiacl.png'
    },
    {
      name: 'Brian Anderson',
      company: 'Fathering Together',
      title: 'Co-Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688024390/speakers/Brian_Anderson_ukmrm6.png'
    },
    {
      name: 'Halle Tecco',
      company: 'Rock Health, Natalist, EVP at Everly Well',
      title: 'Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688129454/speakers/Halle-Tecco_Natalist_Headshot_aoqnso.jpg'
    },
    {
      name: 'Anna Steffeney',
      company: 'Famtech',
      title: 'Executive Director',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688129455/speakers/Anna_Steffeney_vyyhse.jpg'
    },
    {
      name: 'Ai-Jen Poo',
      company: 'Caring Across',
      title: 'Co-Founder',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688131123/speakers/ai-jen-poo_rfljvn.png'
    },
    {
      name: 'Melinda Garrett',
      company: 'Childcare Innovation Lab, NYCEDC',
      title: 'Asst VP',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135954/speakers/Melinda_Garett_lyeptn.jpg'
    },
    {
      name: 'Nikki Beck Kamkar',
      company: 'M & A, Deloitte',
      title: 'Principal',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135958/speakers/nikki-beck_buatbj.jpg'
    },
    {
      name: 'Dr. Tana M. Session',
      company: 'TanaMSession.com',
      title: 'DEI Strategist',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135959/speakers/tana_lm14ac.jpg'
    },
    {
      name: 'Michael Perry',
      company: 'Maple',
      title: 'CEO',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135973/speakers/Michael-Perry_lfpeiy.png'
    },
    {
      name: 'Celinda F. Appleby',
      company: 'Visa',
      title: 'Global Director of Talent',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1688135975/speakers/celinda_yurpoo.png'
    },
    {
      name: 'Massella Dukuly',
      company: 'Charter',
      title: 'Head of Workplace Strategy & Innovation',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1680725582/MASSELA_l8qrfr.jpg'
    }
  ];


  export const SUMMIT_GALLERY = [
    {
      title: 'Start To Flourish 1',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/slide1.jpg'
    },
    {
      title: 'Start To Flourish 2',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/slide2.jpg'
    },
    {
      title: 'Start To Flourish 3',
      imgSrc: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/slide3.jpg'
    },
    {
      title: 'Mother Honestly Summit',
      imgSrc: 'https://res.cloudinary.com/mother-honestly/image/upload/v1672917104/Image_3_rntun7.png'
    },
    {
      title: 'Level Up',
      imgSrc: 'https://flourish.motherhonestly.com/images/image9.jpeg'
    },
    {
      title: 'Mother The Summit',
      imgSrc: 'https://flourish.motherhonestly.com/images/image5.jpeg'
    },
    {
      title: 'Prosper Summit',
      imgSrc: 'https://flourish.motherhonestly.com/images/image4.jpg'
    },
    {
      title: 'Start To Flourish',
      imgSrc: 'https://flourish.motherhonestly.com/images/image3.jpg'
    }
    
  ];

  export const SUMMIT_CAF =  [
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide10.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide2.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide3.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide4.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide5.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide6.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide7.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide8.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw3.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper1.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper2.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw2.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide9.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper5.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw8.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper7.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-slide7.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw5.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper9.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw6.jpg",
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper8.jpg"
];


export const SummitSlideData = [
 { 
  text: "Childcare and family-friendly work policies are often overlooked aspects of social and economic development—but they shouldn’t be",
  author: "Dahlia Khalifa, IFC Director for Central Africa, Liberia, Nigeria and Sierra Leone"
},
 { 
  text: "Creating a nurturing work environment for nursing mothers and their families is not only a social responsibility but also a key driver of employee well-being and productivity",
  author: "Director-General of Nigeria Employers’ Consultative Association, Adewale-Smatt Oyerinde"
},
 { 
  text: "As we continue to increase the rate of women’s participation in paid work, we need to raise the rate of men’s participation in unpaid care and domestic work. This requires action from governments, businesses, trade unions, and women’s organizations to mobilize resources and change cultures. ",
  author: "Blessing Adesiyan"
},
 { 
  text: "We have to treat people for who they are and not just what they do and I think that is a mandate for anyone in a leadership role",
  author: "Paul Saiedi,Director of DE & I, Uber"
},
 { 
  text: "As leaders we have to be mindful that things that happen outside the workplace influence the way people show up within the workplace",
  author: "Donald Knight, Chief People Officer, Greenhouse"
},
 
]

  export const ProsperSummitReviews =  [
    {
      text: "Had a good time yesterday at @prospersummit. It wasn't just about the rich information being passed on or reconnecting and connecting with friends and contacts. What stayed with me was seeing how a dream, a vision comes into fruition. How years of investing time, energy, resources; how a dream in one's heart becomes a reality.Well done @lagosmums and @motherhonestly. I am encouraged!",
      author: "Yemisi Ogunlade",
    },
    {
      text: "I had an amazing time today at the Prosper Summit. So many lessons and experiences shared by speakers on issues that women face at home, in the workplace, and in the society.      ",
      author: "Ayishat Olanrewaju",
    },
    {
      text: "About yesterday at the Prosper Summit organized by @lagosmums and @motherhonestly. Meaningful and honest conversation. Exciting time with my new sisters.I really enjoyed every part from the yoga to the panel sessions.      ",
      author: "Adesuwa Oham",
    },
    {
      text: "A Hugely Thank you to the leaderships of @prospersummit for inviting me to this Powerpacked summit, I had a blast.Let's keep having meaningful conversations...This is how we will change the world.      ",
      author: "Dr May Kadiri",
    },
    
  ];

  
  export const CONTACT_TITLE_LEVEL: SelectOption<string>[] = [
    {
      label: "Executive",
      value: "Executive",
    },
    {
      label: "Executive Assistant",
      value: "Executive Assistant",
    },
    {
      label: "HR",
      value: "HR",
    },
    {
      label: "Manager",
      value: "Manager",
    },
  ];

  export const CONTACT_ROLE: SelectOption<string>[] = [
    {
      label: "CEO / Founder",
      value: "CEO / Founder",
    },
    {
      label: "COO",
      value: "COO",
    },
    {
      label: "HR Executive",
      value: "HR Executive",
    },
    {
      label: "Communications Manager",
      value: "Communications Manager",
    },
  ];

  export const CONTACT_MODE: SelectOption<string>[] = [
    {
      label: "Phone",
      value: "Phone",
    },
    {
      label: "Email",
      value: "Email",
    },
   
  ];

  export const OrgSize: SelectOption<string>[] = [
    {
      label: "0 - 1,000",
      value: "0 - 1,000",
    },
    {
      label: "1,000 - 5,000",
      value: "1,000 - 5,000",
    },
    {
      label: "5,000 - 10,000",
      value: "5,000 - 10,000",
    },
    {
      label: "10,000 and above",
      value: "10,000 and above",
    },
   
  ];
  export const YesOrNo: SelectOption<string>[] = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
   
  ];

  export const CONTACT_STATE: SelectOption<string>[] = [
    {
      label: "New York",
      value: "New York",
    },
    {
      label: "Ohio",
      value: "Ohio",
    },
    {
      label: "Virginia",
      value: "Virginia",
    },
    {
      label: "Florida",
      value: "Florida",
    },
  ];

  export const CONTACT_EMPLOYEE_NO: SelectOption<string>[] = [
    {
      label: "10 - 50",
      value: "10 - 50",
    },
    {
      label: "50 - 100",
      value: "50 - 100",
    },
    {
      label: "100 - 150",
      value: "100 - 150",
    },
    {
      label: "150 - 200",
      value: "150 - 200",
    },
  ];

  export const CONTACT_INTEREST: SelectOption<string>[] = [
    {
      label: "General Information",
      value: "General Information",
    },
    {
      label: "Finance",
      value: "Finance",
    },
    {
      label: "Parenting",
      value: "Parenting",
    },
    {
      label: "Motherhood",
      value: "Motherhood",
    },
    {
      label: "Well being",
      value: "Well being",
    },
  ];
