import { Box } from "@mui/material";
import React from "react";
import MHButton from "../Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import Typography, { typographyClasses } from "@mui/material/Typography";

type Props = {};

const TakeSurvey = (props: Props) => {
  let history = useHistory();
  return (
    <React.Fragment>
      <Box
        py={7}
        px={{ xs: 2, md: 6, lg: 8 }}
        bgcolor="primary.main"
        color="#EDD5B1"
        sx={{
          [`& > .${typographyClasses.root}`]: {
            color: "#EDD5B1",
            textAlign: "center",
          },
        }}
      >
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          {/* The State of Work And Family In Africa 2023 */}
          The Business and Economic Case For Care In Africa
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize={{ sm: "14px", md: "16px" }}
          lineHeight={"150%"}
          letterSpacing={"0.02em"}
          align="center"
          mx="auto"
          paragraph
        >
          {/* There is little to no data on the way we live and work in Africa. We
          are changing that narrative. Join us by taking the 2nd Annual State Of
          Work And Family In Africa survey */}
          At the Caring Africa Summit, we presented the business case for care
          report. This groundbreaking document underscores the integral role of
          care in driving economic prosperity across the continent. Drawing on
          expert opinions, case studies, and empirical data, the report
          highlights the potential for significant economic returns, improved
          workforce productivity, and enhanced quality of life.
        </Typography>

        <Box className="mx-auto text-center py-10 md:py-8">
          <MHButton
            // onClick={() => history.push("/")}
            onClick={() =>
              window.open(
                "https://issuu.com/mhworklife/docs/the_business_case_for_care_2024-compressed"
              )
            }
            // onClick={() => window.open("https://form.typeform.com/to/eLJqW2tq")}
            color="secondary"
            className="capitalize text-[10px] md:text-[10px] lg:text-[12px] leading-[102%] tracking-[0.05em] w-[160px] md:w-[160px] lg:w-[176px] md:h-[50px] lg:h-[52px] py-5"
          >
            {/* Take The Survey */}
            View Report
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TakeSurvey;
