import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import MHButton from "../Common/Button/MHButton";
import BlessingDialog from "../Contact/BlessingDialog";

import { ReactComponent as PlusIcon } from "../../static/svg/plus.svg";
import { ReactComponent as MinusIcon } from "../../static/svg/minus.svg";

type Props = {
  title?: string;
  titleB?: string;
  label?: string;
  content?: string;
  content2?: string;
  demoText?: string;
  demoWidth?: string;
  imgSrc?: string;
  demoClass?: string;
  urlClass?: string;
  url?: string;
  urlText?: string;
  order?: string;
};

const ImageGrid = (props: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let history = useHistory();

  const handleOpenUrl = () => {
    history.push(`/${props.url}`);
  };

  const ReturnText = () => {
    const text = `Blessing Adesiyan is the Founder & CEO of MH WorkLife where she is dedicated to building care infrastructure for today's workforce and advancing equity, productivity, and wellbeing in today's ever-evolving workplaces. 

    Blessing started her career leading engineering, supply chain, and operational excellence divisions at Fortune 100 companies across North America, Europe, Middle East, Africa, and Asia.  Her career at BASF, DuPont, PepsiCo, and Cargill offered first-hand exposure to the pervasive gaps impeding wellbeing and productivity as a parent, caregiver, immigrant, and Black woman. After working to close that gap with her employers, she started MH WorkLife to power care at home and in the workplace. MH have supported startups, SMBs, and large corporations in closing the care and productivity gap at work while improving career, financial, social, physical, and community wellbeing for employees of ALL kinds. She Is a highly-sought after leader and keynote speaker on the future of work, with OpEds and Features In Time Magazine, WSJ, Fast Company, Forbes, Techcrunch and more. She has spoken at Fast Company, TechCunch, NASA, Google, Unilever, American Bankers Association and more.
    
    Blessing holds a Chemical Engineering degree from the University of Florida, a Masters In Energy Management from the New York Institute Of Technology, and an MBA from the UNC Kenan-Flagler Business School.
    `;

    const [isSliced, setIsSliced] = React.useState(true);

    const toggleSlicedText = () => {
      setIsSliced(!isSliced);
    };

    const slicedText = text.split(" ").slice(0, 100).join(" ");
    const displayedText = isSliced ? slicedText : text;

    return (
      <Box className="">
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className={`w-[333px] md:w-[456px] pt-4 text-center mx-auto ${props.content2}`}
        >
          {displayedText}{" "}
          <Link component="button" onClick={toggleSlicedText}>
            {isSliced ? <PlusIcon /> : <MinusIcon />}
          </Link>
        </Typography>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        className="flex py-8 items-center justify-center overflow-hidden"
      >
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={`${props.order}`}
        >
          <Box className="text-center">
            <Typography
              variant="h3"
              color="primary"
              fontSize={{ xs: "30px", sm: "38px", md: "43px" }}
              lineHeight={{ xs: "120%", sm: "120.5%" }}
              letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
              className="capitalize py-4 w-[302px] md:w-[396px] text-center mx-auto"
            >
              {props.title}
            </Typography>

            {props.titleB && (
              <Typography
                variant="h3"
                color="primary"
                fontSize={{ xs: "26px", sm: "30px", md: "32px" }}
                lineHeight={{ xs: "120%", sm: "120.5%" }}
                letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
                className="capitalize py-4 w-[302px] md:w-[396px] text-center mx-auto"
              >
                {props.titleB}
              </Typography>
            )}

            <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "11px", sm: "12px" }}
              lineHeight={{ xs: "230%", sm: "102%" }}
              letterSpacing={"0.1em"}
              className="uppercase text-center mx-auto w-[335px] md:w-[369px] "
            >
              {props.label}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "180%", sm: "195%" }}
              letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
              className=" w-[333px] md:w-[451px] text-center mx-auto"
            >
              {props.content}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "180%", sm: "195%" }}
              letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
              className={`w-[333px] md:w-[451px] pt-4 text-center mx-auto ${props.content2}`}
            >
              <ReturnText />
            </Typography>

            <Box className={props.demoClass}>
              {/* Web Button */}
              <MHButton
                onClick={() => handleOpen()}
                className={`mx-auto mt-10 hidden lg:block text-[12px] leading-[102%] ${props.demoWidth} tracking-[0.05em] h-[52px]`}
              >
                {props.demoText}
              </MHButton>

              {/* Mobile Button */}

              <Box className="lg:hidden mt-10 flex justify-center">
                <MHButton
                  onClick={() => handleOpen()}
                  className={`text-[12px] leading-[102%] ${props.demoWidth} tracking-[0.05em] h-[52px]`}
                >
                  {props.demoText}
                </MHButton>
              </Box>
            </Box>

            <Box className={props.urlClass}>
              {/* Web Button */}
              <MHButton
                onClick={() => handleOpenUrl()}
                className={`mx-auto mt-10 hidden lg:block text-[12px] leading-[102%] ${props.demoWidth} tracking-[0.05em] h-[52px]`}
              >
                {props.demoText}
              </MHButton>

              {/* Mobile Button */}

              <Box className="lg:hidden mt-10 flex justify-center">
                <MHButton
                  onClick={() => handleOpenUrl()}
                  className={`text-[12px] leading-[102%] ${props.demoWidth} tracking-[0.05em] h-[52px]`}
                >
                  {props.demoText}
                </MHButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
          <Box className=" md:p-10 ">
            <img
              src={props.imgSrc}
              alt=""
              className=" mx-auto h-[343px] w-[343px] md:w-[450px] md:h-[450px] lg:h-[630px] lg:w-[630px] rounded-2xl object-cover"
            />
          </Box>
        </Grid>
      </Grid>

      <BlessingDialog open={open} onClose={handleClose} />
    </React.Fragment>
  );
};

export default ImageGrid;
