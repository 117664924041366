import React from "react";
import StoryHeader from "../components/Story/StoryHeader";

import { ReactComponent as ResLove } from "../static/svg/reslove.svg";
import EquityBannerIcon from "../static/svg/banner2.svg";
import AboutAfrica from "../components/Africa/AboutAfrica";
import ProgramsAndObjectives from "../components/Africa/ProgramsAndObjectives";
import MajorHighlights from "../components/Africa/MajorHighlights";
import MHFooter from "../components/Layout/MHFooter";
import CoreActivities from "../components/Africa/CoreActivities";

type Props = {};

const Africa = (props: Props) => {
  return (
    <React.Fragment>
      <StoryHeader
        title2="Caring Africa"
        textColor="primary"
        icon={<ResLove />}
        imageSrc={EquityBannerIcon}
        imageMobSrc={EquityBannerIcon}
        headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[331px] mx-auto"
      />
      <AboutAfrica />
      <ProgramsAndObjectives />
      <CoreActivities />
      {/* <MajorHighlights /> */}
      <MHFooter />
    </React.Fragment>
  );
};

export default Africa;
