import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as EventAdvertPic } from "../../static/svg/eventAdvert.svg";
import { ReactComponent as EventIcon } from "../../static/svg/eventicon.svg";

import MHButton from "../Common/Button/MHButton";
import Overlay from "../UI/Overlay";

// type Props = {}

const EventAdvert = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/recap-summit/2024/caring-africa-summit`);
  };
  return (
    <React.Fragment>
      <Box>
        <Box className="relative overflow-hidden">
          {/* <EventAdvertPic className="w-fit hidden md:block h-[700px] object-cover" /> */}
          <Box position="relative">
            <Overlay />
            <img
              // src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAFeventAdvert.jpg"
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/6Y6A8862.jpg"
              alt=""
              className="w-full hidden md:block h-[700px] object-cover"
            />
            <img
              // src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAFeventAdvert.jpg"
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/6Y6A8862.jpg"
              alt=""
              className="w-full block md:hidden h-[750px] object-cover"
            />
          </Box>

          <Box className="absolute top-[5%] md:top-10 text-start md:text-center left-[20px] md:left-0 md:right-0">
            <EventIcon className="md:mx-auto" />

            <Typography
              variant="caption"
              fontSize={{ xs: "12px", sm: "15px", md: "18.3px" }}
              lineHeight={{ xs: "100%", sm: "189%" }}
              letterSpacing={"0.1em"}
              color={"white"}
              className="py-4 uppercase text-start md:text-center"
            >
              February 23, 2024 | 9:30am WAT
            </Typography>

            {/* <Typography className=" font-columbia text-[40px] text-start md:text-center md:text-[55px]  lg:text-[67.7px] leading-[103%] md:leading-[105.5%] tracking-[-0.05em] text-white ">
              Caring Workplaces Summit
            </Typography> */}
            <Typography
              variant="h2"
              fontSize={{ xs: "40px", sm: "55px", md: "67.7px" }}
              lineHeight={{ xs: "103%", sm: "105.5%" }}
              letterSpacing={"-0.05em"}
              color={"white"}
              className="text-start md:text-center "
            >
              Caring Africa Summit
            </Typography>

            <Box className=" pt-10 pb-2 w-[90%] md:w-[70%] md:mx-auto">
              <Typography
                variant="caption"
                fontSize={{ xs: "11px", sm: "10px", md: "12px" }}
                lineHeight={{ xs: "80%", sm: "189%" }}
                letterSpacing={"0.1em"}
                color={"white"}
                className=" uppercase  text-start md:text-center"
              >
                There Is A Business & Economic Case For Care In Africa
              </Typography>
            </Box>

            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"-0.02em"}
              color={"white"}
              className=" text-start md:text-center w-[90%] md:w-[60%] lg:w-[40%] md:mx-auto"
            >
              Caring Africa Summit will bring together key stakeholders in the
              public and private sector to drive the conversation around care
              for families at home, in the workplace, and communities. Caring
              Africa conceptualizes care as a vital and integral component of
              the societal fabric - from childcare and eldercare to care work
              and healthcare assistance. We recognize that care is a universal
              need, transcending cultural, economic, and social boundaries, and
              is crucial for social and economic development. This includes the
              recognition of the value of care in driving transformative change
              and enhancing the quality of life and work for all Africans.
            </Typography>

            <Box className="mx-auto text-start md:text-center py-10 md:py-8">
              <MHButton
                onClick={() => handleClickOpen()}
                className="bg-white text-navy-900 text-[12px] leading-[102%] tracking-[0.05em]  w-[183px] h-[44px] md:w-[170px] lg:w-[183px] md:h-[50px] lg:h-[52px] py-5"
              >
                Learn More
              </MHButton>
            </Box>

            {/* <Link to={"/resources/events"}>
              <Typography
                variant="body1"
                fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "102%", sm: "105.5%" }}
                letterSpacing={{ xs: "0.05em", sm: "0.1em" }}
                color={"white"}
                className=" py-3 md:py-12 capitalize underline text-start md:text-center "
              >
                Browse this Month's Events
              </Typography>
            </Link> */}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default EventAdvert;
