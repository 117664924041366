import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useRouteMatch } from "react-router-dom";

type Props = {
  stats?: string;
  info?: string;
  header?: string;
};

const StatsCard = (props: Props) => {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Box className="bg-white my-6 lg:my-8 text-center items-center rounded-[10px] h-[260px] w-[267px] md:h-[280px] md:w-[420px] lg:h-[320px] lg:w-[381px]">
        {/* <Box className="bg-white my-6 md:my-2 lg:my-8 text-center items-center rounded-[10px] h-[150px] w-[267px] md:h-[200px] md:w-[420px] lg:h-[229px] lg:w-[320px] md:shadow-md"> */}
        {/* <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "80px" }}
          lineHeight={{ xs: "120%", sm: "105.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.03em" }}
          className="py-4 md:py-6 text-center"
        >
          {props.stats}
        </Typography> */}
        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "75px" }}
          lineHeight={{ xs: "120%", sm: "105.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.03em" }}
          className="py-4 md:py-6 text-center"
        >
          {props.stats}
        </Typography>

        {path.includes("find-care") ? (
          <Typography
            variant="h2"
            fontSize={{ xs: "20px", sm: "25px", md: "30px" }}
            lineHeight={{ xs: "120%", sm: "105.5%" }}
            letterSpacing={{ xs: "-0.04em", sm: "-0.03em" }}
            className="py-2 text-center"
          >
            {props.header}
          </Typography>
        ) : (
          ""
        )}

        <Typography
          variant="body1"
          fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className="text-center w-[75%] lg:w-[80%] mx-auto"
        >
          {props.info}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default StatsCard;
