import * as React from 'react';
import { useHistory } from 'react-router-dom';

import useHttp from '../../hooks/use-http';
import { HttpResponse } from '../../models/api.interface';

import {
  Employee,
  SubscriptionStatus,
  Token,
  User
} from '../../models/user.model';
import { decrypt, encrypt } from '../../utils/utils';
import useDialog from '../../hooks/use-dialog';
import MemberLoginDialog from '../../pages/auth/MemberLoginDialog';
import Startup from '../../components/Features/Auth/Startup';
import BackdropLoader from '../../components/Common/Loading/BackdropLoader';
import useToggle from '../../hooks/use-toggle';
import useLogoutTabs from '../../hooks/use-logout-tabs';

export const AUTH_LOCATION = 'Sn61y6yYDiIxkur0JT';
const TOKEN_VALIDITY = 360 * 60000;
let expirationTimer: any;

type StoredToken = {
  token: Token;
  tokenExpirationDate: Date | string;
  uuid: number;
};

type AuthContextType = {
  isAuthenticated: boolean;
  token: Token | null;
  userId: number | null;
  user: User | null;
  auxUserDetails: AuxUserDetails | null;
  isOnboarded: (user: User) => boolean;
  login: (token: Token, uuid: number) => void;
  logout: () => void;
  refreshTokenPair: (
    refreshToken: string,
    onRefresh?: (token: Token) => void
  ) => void;
  synchronizeUser: (emp: Employee) => void;
  updateUserData: (updatedUser: Employee) => void;

  openAuthDialog: (
    onAuthenticate?: (user: User) => void,
    onTerminateAuth?: () => void
  ) => void;

  closeAuthDialog: () => void;
};

type AuxUserDetails = {
  jobTitle: string;
  position: string;
  workDepartment: string;
  zipCode: string;
  headShotName: string;
  dependants: string;
  employmentStatus: string;
  ageOfChildren: string;
  ageOfDependant: string;
  householdAnnualIncome: string;

  dateOfBirth: string;
  numberOfKids: string;
  householdSize: number;
  race: string;
  relationshipStatus: string;
  identity: string;
  state: string;
  stripeCustomerId: string;
  subscriptionStatus: SubscriptionStatus;
  /**
   * stringified json object: call JSON.parse to read properties
   */
  metadata: string;
};

const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  token: null,
  userId: null,
  user: null,
  auxUserDetails: null,
  isOnboarded: (user: User) => false,
  login: (token: Token, uuid: number) => {},
  logout: () => {},
  refreshTokenPair: (
    refreshToken: string,
    onRefresh?: (token: Token) => void
  ) => {},
  synchronizeUser: (emp: Employee) => {},
  updateUserData: (updatedUser: Employee) => {},
  openAuthDialog: (
    onAuthenticate?: (user: User) => void,
    onTerminateAuth?: () => void
  ) => {},
  closeAuthDialog: () => {}
});

const computeExpirationInMilliSecs = (expirationTime: Date) => {
  const currentTimeStamp = new Date().getTime();
  const expirationTimeStamp = new Date(expirationTime).getTime();
  //   currentTimeStamp + TOKEN_VALIDITY

  const remainingTime = expirationTimeStamp - currentTimeStamp;
  return remainingTime;
};

const retrieveStoredToken = () => {
  const encStoredToken: string = localStorage.getItem(AUTH_LOCATION) as string;

  if (!encStoredToken) {
    return null;
  }

  const storedToken: StoredToken = JSON.parse(decrypt(encStoredToken));

  const expirationTimeInMilliSecs = computeExpirationInMilliSecs(
    storedToken.tokenExpirationDate as Date
  );

  if (expirationTimeInMilliSecs <= 60000) {
    localStorage.removeItem(AUTH_LOCATION);
    return null;
  }

  return storedToken;
};

export const AuthContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const tokenData = retrieveStoredToken();
  let initialToken = !!tokenData ? tokenData.token : null;
  let initialUserId = !!tokenData ? tokenData.uuid : null;

  const [token, setToken] = React.useState<Token | null>(initialToken);
  const [userId, setUserId] = React.useState<number | null>(initialUserId);
  const [user, setUser] = React.useState<User | null>(null);
  const [
    auxUserDetails,
    setAuxUserDetails
  ] = React.useState<AuxUserDetails | null>(null);

  const history = React.useMemo(() => useHistory, [])();

  const { loading: signingOut, sendHttpRequest: logout } = useHttp();
  const { sendHttpRequest: resetToken } = useHttp();

  const logoutHandler = React.useCallback(() => {
    setToken(null);
    setUser(null);

    if (expirationTimer) {
      clearTimeout(expirationTimer);
    }
    localStorage.removeItem(AUTH_LOCATION);
    // history.replace('/auth/sign-in');

    logout(
      process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/logout',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uuid: user?.uuid
        })
      },
      () => {}
    );
  }, [user, logout]);

  useLogoutTabs(logoutHandler);

  const loginHandler = React.useCallback((token: Token, uuid: number) => {
    resetUser();
    setToken(token);
    setUserId(uuid);
    const expirationTime = new Date(new Date().getTime() + TOKEN_VALIDITY);
    // setExpirationTimer(expirationTime);

    const storedToken: StoredToken = {
      token,
      tokenExpirationDate: expirationTime,
      uuid
    };

    localStorage.setItem(AUTH_LOCATION, encrypt(JSON.stringify(storedToken)));
  }, []);

  const setExpirationTimer = React.useCallback(
    (expirationTime: Date) => {
      if (tokenData)
        expirationTimer = setTimeout(() => {
          if (!token) {
            logoutHandler();
            return;
          }
          refreshTokenPair(token.refreshToken);
        }, computeExpirationInMilliSecs(expirationTime));
    },
    [logoutHandler, tokenData]
  );

  const resetUser = () => {
    setUser(null);
    setUserId(null);
    setToken(null);
  };

  React.useEffect(() => {
    // setExpirationTimer(tokenData?.tokenExpirationDate as Date);
  }, [tokenData, setExpirationTimer]);

  const refreshTokenPair = React.useCallback(
    (refreshToken: string, onRefresh?: (token: Token) => void) => {
      resetToken(
        process.env.REACT_APP_API_BASE_URL + 'auth/token/new',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.refreshToken}`
          }
        },
        (responseData: HttpResponse<Token>) => {
          const tokenPair = responseData.data;
          onRefresh && onRefresh(tokenPair);
          loginHandler(tokenPair, Number(userId));
        }
      );
    },
    [resetToken, token, loginHandler, userId]
  );

  const {
    openDialog: isAuthDialogOpen,
    handleOpenDialog,
    handleCloseDialog
  } = useDialog();

  const onUserAuthenticated = React.useRef<(user: User) => void>(
    (user: User) => {}
  );

  const terminateAuth = React.useRef<() => void>(() => {});

  const openAuthDialog = (
    onAuthenticate?: (user: User) => void,
    onTerminateAuth?: () => void
  ) => {
    onUserAuthenticated.current = onAuthenticate
      ? onAuthenticate
      : (user: User) => {};
    handleOpenDialog();

    if (onTerminateAuth) {
      terminateAuth.current = onTerminateAuth;
    } else {
      terminateAuth.current = () => {};
    }
  };

  const closeAuthDialog = () => {
    handleCloseDialog();
    if (!token && isAuthDialogOpen) {
      terminateAuth.current();
    }
  };

  const synchronizeUser = React.useCallback(
    (responseData: Employee) => {
      const {
        firstName,
        lastName,
        workEmail,
        onboarded,
        ...others
      } = responseData;
      const user = new User(
        Number(userId),
        firstName,
        lastName,
        workEmail,
        tokenData?.token as Token,
        tokenData?.tokenExpirationDate as Date,
        onboarded,
        others.headShotName
      );
      setUser(user);
      setAuxUserDetails({
        jobTitle: others.jobTitle,
        position: others.position,
        workDepartment: others.workDepartment,
        zipCode: others.zipCode,
        headShotName: others.headShotName,
        dependants: others.dependants,
        employmentStatus: others.employmentStatus,
        ageOfChildren: others.ageOfChildren,
        ageOfDependant: others.ageOfDependant,
        householdAnnualIncome: others.houseHoldAnnualIncome,
        dateOfBirth: others.dateOfBirth,
        numberOfKids: others.numberOfKids,
        householdSize: others.houseHoldSize,
        race: others.race,
        relationshipStatus: others.relationShipStatus,
        identity: others.identity,
        state: others.state,
        stripeCustomerId: others.stripeId,
        subscriptionStatus: others.stripeSubIsActive as SubscriptionStatus,
        metadata: others.metaData
      });
      onUserAuthenticated.current(user);
    },
    [tokenData, userId]
  );

  const updateUserData = React.useCallback(
    ({ firstName, lastName, ...others }: Employee) => {
      const { email } = user as User;
      const updatedUser = new User(
        Number(userId),
        firstName,
        lastName,
        email,
        tokenData?.token as Token,
        tokenData?.tokenExpirationDate as Date,
        true, // do not change, this is correct as hardcoded
        others.headShotName
      );
      setUser(updatedUser);
      setAuxUserDetails({
        jobTitle: others.jobTitle,
        position: others.position,
        workDepartment: others.workDepartment,
        zipCode: others.zipCode,
        headShotName: others.headShotName,
        dependants: others.dependants,
        employmentStatus: others.employmentStatus,
        ageOfChildren: others.ageOfChildren,
        ageOfDependant: others.ageOfDependant,
        householdAnnualIncome: others.houseHoldAnnualIncome,
        dateOfBirth: others.dateOfBirth,
        numberOfKids: others.numberOfKids,
        householdSize: others.houseHoldSize,
        race: others.race,
        relationshipStatus: others.relationShipStatus,
        identity: others.identity,
        state: others.state,
        stripeCustomerId: others.stripeId,
        subscriptionStatus: others.stripeSubIsActive as SubscriptionStatus,
        metadata: others.metaData
      });
    },
    [tokenData, userId, user]
  );

  const contextValue: AuthContextType = {
    token: token,
    isAuthenticated: !!token,
    userId: userId,
    user: user,
    auxUserDetails,
    isOnboarded: (user: User) => user.onboarded,
    login: loginHandler,
    logout: logoutHandler,
    refreshTokenPair,
    synchronizeUser,
    updateUserData,
    openAuthDialog,
    closeAuthDialog
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {signingOut && <BackdropLoader open={signingOut} />}
      {<Startup />}
      {isAuthDialogOpen && (
        <MemberLoginDialog open={isAuthDialogOpen} onClose={closeAuthDialog} />
      )}
      {/* {userId && !user && <Startup />} userid is set when login is triggered, we want to render Startup on this and user = null */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
