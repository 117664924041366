import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography, { typographyClasses } from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import Membership from "../components/Features/Landing/MembershipWidget";
import MembershipPlans from "../components/Features/Memberships/MembershipPlans";
import MembershipFeatures from "../components/Features/Memberships/MembershipFeatures";

import Divider from "@mui/material/Divider";

import FollowMH from "../components/Landing/FollowMH";
import MHFooter from "../components/Layout/MHFooter";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHButton from "../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import EnterprisePlan from "../components/Landing/EnterprisePlan";
import PlatformFeatures from "../components/Features/Memberships/PlatformFeatures";
import { MagazineFeature } from "./Employers";
import ImageGrid from "../components/Story/ImageGrid";

const Childcare = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  const membershipPlansDivRef = React.useRef<{
    divEl: HTMLDivElement | null;
  }>({ divEl: null });

  return (
    <React.Fragment>
      <Grid container direction={{ xs: "column", md: "row" }} mb={4}>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={500}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              "& > p": {
                textAlign: "center",
                pointerEvents: "none",
              },
            }}
          >
            <Typography
              variant="caption"
              textTransform="uppercase"
              fontSize="11px"
              letterSpacing="0.1em"
              lineHeight="230%"
              sx={{
                mb: 5,
              }}
              paragraph
            >
              Childcare
            </Typography>

            <Typography
              variant="h1"
              fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
              textTransform="capitalize"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Join Us in Shaping Africa's Future
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              fontSize="13px"
              lineHeight="185%"
              letterSpacing="0.02em"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Together, we can transform the childcare landscape in Africa,
              creating a brighter future for our children and empowering
              families across the continent. Be a part of this transformative
              journey with Caring Africa.
            </Typography>

            {/* <MHButton
              sx={{
                width: "fit-content",
              }}
              onClick={() =>
                membershipPlansDivRef.current?.divEl?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              More Info
            </MHButton> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              "& > img": {
                height: 600,
                width: "100%",
                objectPosition: "bottom",
                objectFit: "cover",
              },
            }}
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/addprosper2.jpg"
              alt="Banner"
              width="700"
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        py={7}
        px={{ xs: 2, md: 6, lg: 8 }}
        color="primary.main"
        sx={{
          [`& > .${typographyClasses.root}`]: {
            // color: '#EDD5B1',
            textAlign: "center",
          },
        }}
      >
        {/* <Typography
          variant="caption"
          fontSize="12px"
          lineHeight="102%;"
          letterSpacing="0.1em"
          paragraph
        >
          childcare
        </Typography> */}

        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          The Essential Role of Childcare in Africa's Growth
        </Typography>
      </Box>

      {/* <Divider
        variant="middle"
        sx={{
          borderColor: "primary.main",
          width: "91%",
          mx: "auto",
          opacity: 0.1,
          my: 8,
        }}
      /> */}

      <ImageGrid
        title="The Need for Quality Childcare        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/childcare4.png"
        content="In Africa, and particularly in Nigeria, childcare is not just a service, but a crucial pillar for socio-economic development. The World Bank's Invest in Childcare report highlights that access to quality, affordable childcare is key to building human capital, accelerating equality, and placing women at the center of economic growth​​. This is especially pertinent in a continent where, as the report notes, over 40 percent of children globally in need of childcare are in low and lower-middle-income countries​​."
        content2="hidden"
      />
      <ImageGrid
        title="Impact on Women and Families"
        demoClass="hidden"
        urlClass="hidden"
        order="order-last lg:order-first"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/childcare2.png"
        content="For women, access to childcare is transformative. It enables mothers to participate in the labor market, improving their productivity, earnings, and the quality of work they can undertake. This empowerment of women is critical in regions like Nigeria, where gender disparities are pronounced and women's economic participation is essential for societal progress​​.        "
        content2="hidden"
      />
      <ImageGrid
        title="Early Childhood Care and Education (ECCE)        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/childcare3.png"
        content="ECCE is more than just preparation for school. As UNESCO defines, it is a holistic approach, encompassing social, emotional, cognitive, and physical needs, vital from birth to eight years old​​. In Nigeria, ECCE is a pivotal part of sustainable growth, promoting human resource development, gender equality, and social cohesion​​.        "
        content2="hidden"
      />
      <ImageGrid
        title="Challenges and Opportunities        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last lg:order-first"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/childcare1.png"
        content="Despite the clear benefits, the implementation of effective childcare policies faces significant challenges in Nigeria. Research indicates that while policies exist, their practical implementation is lacking. There is a notable gap in the quality and accessibility of early childcare, particularly in private sectors where the majority of children are enrolled​​. This presents an opportunity for Caring Africa to advocate for and support the development of quality, accessible childcare services that meet the needs of all families.        "
        content2="hidden"
      />

      <Box className="text-center my-10 overflow-hidden">
        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          Our Commitment
        </Typography>

        <Typography
          variant="body2"
          fontSize={{ xs: "13px", md: "18px" }}
          lineHeight={{ xs: "200%", md: "169%" }}
          letterSpacing="0.02em"
          width={{ xs: "90%", md: "60%" }}
          mx="auto"
          mb={4}
        >
          At Caring Africa, we are dedicated to bridging these gaps. We advocate
          for robust policy implementation, support the development of childcare
          infrastructure, and work towards making quality child care accessible
          to all families, regardless of socio-economic status. By investing in
          childcare, we are investing in the future of Africa – ensuring better
          jobs, brighter futures, and a more vibrant economy.
        </Typography>
      </Box>

      <MHSubscribe />

      <MHFooter />
    </React.Fragment>
  );
};

export default Childcare;
