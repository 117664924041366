import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import * as validators from "../../utils/validators";
import { ReactComponent as ArrowRightIcon } from "../../static/svg/arrow-right.svg";

import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import useDialog from "../../hooks/use-dialog";
import useInput from "../../hooks/use-input";

import MHFormGroup from "../Common/Form/MHFormGroup";
import { Box } from "@mui/material";
import MHButton from "../Common/Button/MHButton";
import MHTextInput from "../Common/Form/MHTextInput";
import RequestConfirmationPrompt from "../Contact/RequestConfirmation";

const QuotientSubscribe = () => {
  const [error, setError] = React.useState(false);
  // const { loading, error, sendHttpRequest } = useHttp();

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const errorMail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(true);
  };

  const flodeskSubmit = async () => {
    // e.preventDefault();
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "MH WorkLife (www.mhworklife.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // first_name: firstName,
          // last_name: lastName,
          email: enteredWorkEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
        }),
      });
      flodeskSegmentSubmit();
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651c4926b92f35b8012957c1"]; // Replace with actual segment IDs
    // const testsegmentIds = ["64edd24f139c9cfad37e4808"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredWorkEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredWorkEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "MH WorkLife (www.mhworklife.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const newsLetterHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "newsletter-subscriptions"), {
      NewsLetterEmail: enteredWorkEmail,
      createdAt: serverTimestamp(),
    });

    handleOpenDialog();
    flodeskSubmit();
    resetForm();
    setError(false);

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const resetForm = () => {
    workEmailInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <Stack
      className="quotient-component"
      justifyContent="center"
      alignItems="center"
      sx={{
        "& svg": {
          color: "common.white",
        },
        "& button:hover svg": {
          color: "primary.main",
        },
      }}
    >
      <Typography
        variant="body1"
        fontFamily="Area-Normal-Black"
        textTransform="uppercase"
        color="#194049"
        fontSize={13}
        letterSpacing="0.1em"
        lineHeight="102%"
        sx={{
          mb: 2,
          paddingBottom: 3,
        }}
      >
        SUBSCRIBE TO THE NEWSLETTER
      </Typography>

      <Typography
        variant="h1"
        align="center"
        paragraph
        className="md:block hidden w-3/4"
        sx={{
          mb: 2,
          paddingBottom: 5,
        }}
      >
        Data-driven insights and expert conversations on caregiving, work, and
        life, delivered to your inbox weekly.
      </Typography>

      <Typography
        variant="h3"
        align="center"
        // textTransform="capitalize"
        paragraph
        className="block md:hidden font-areaSemi"
        sx={{
          mb: 2,
          paddingBottom: 5,
        }}
      >
        Data-driven insights and expert conversations <br /> on caregiving,
        work, and life, <br /> delivered to your inbox weekly.
      </Typography>

      {/* <MHFormGroup
        onSubmit={
          validators.email(enteredWorkEmail) === true
            ? newsLetterHandler
            : errorMail
        }
        disableWhileSubmitting={
          validators.email(enteredWorkEmail) === true
            ? httpState.loading
            : false
        }
      >
        <Box
          component={"div"}
          width={250}
          display="flex"
          bgcolor={"#F1F1F1"}
          sx={{
            width: "380px",
          }}
        >
          <MHTextInput
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={enteredWorkEmail}
            onChange={workEmailInputChangeHandler}
            onBlur={workEmailInputBlurHandler}
            required
            className="flex-grow h-fit"
          />

          <MHButton
            type="submit"
            loading={httpState.loading}
            sx={{
              minWidth: "auto",
              "& svg": {
                stroke: "grey.500",
                width: "1rem",
              },
              "&.MuiButton-root:hover svg": {
                stroke: "primary",
              },
            }}
          >
            {<ArrowRightIcon />}
          </MHButton>
        </Box>

        {error === false ? (
          ""
        ) : enteredWorkEmail !== "" ? (
          ""
        ) : (
          <Typography
            variant="body2"
            color="red"
            fontSize={{ xs: "11px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "230%", sm: "102%" }}
            letterSpacing={"0.1em"}
            sx={{
              mb: 2,
              pt: 1,
              paddingBottom: 3,
            }}
          >
            Enter a valid email address
          </Typography>
        )}
      </MHFormGroup> */}

      <MHButton
        loading={httpState.loading}
        onClick={() => {
          window.open("https://thecaregap.substack.com/");
        }}
        sx={{
          minWidth: "auto",
          "& svg": {
            stroke: "grey.500",
            width: "1rem",
          },
          "&.MuiButton-root:hover svg": {
            stroke: "primary",
          },
          gap: "5px",
        }}
      >
        Subscribe {<ArrowRightIcon />}
      </MHButton>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="Subscription Successful!"
          message="Thank you for subscribing on MH WorkLife weekly newsletter. We will be in touch with
          you shortly."
        />
      )}
    </Stack>
  );
};

export default QuotientSubscribe;
