import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { PartnershipCard } from "../Equity/PartnershipCard";

type Props = {};

const CoreActivities = (props: Props) => {
  return (
    <Fragment>
      <Box className="bg-lilac-300 overflow-hidden pb-10">
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "40px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize pt-10 pb-6 md:w-[594px] w-[298px] text-center mx-auto"
        >
          {/* Our Core Programs And Objectives */}
          Core Activities
        </Typography>
        <Grid
          container
          spacing={0}
          className="lg:ml-5 lg:px-12 md:gap-4 lg:gap-0 md:mx-auto md:place-content-center"
        >
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper12.png"
              title="Childcare Advocacy"
              content="Advocate for accessible, quality childcare services through partnerships with local communities and employing specific advocacy methods, recognizing their critical role in supporting families and communities."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper11.png"
              title="Promote Equitable Paid Leave"
              content="Implement equitable paid leave policies, enabling individuals to balance care responsibilities with economic activities."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper13.png"
              title="Advance Aging Care Services"
              content="Develop and enhance long-term care services to meet the diverse needs of Africa's population."
            />
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper7.png"
              title="Shape the Future of Workers"
              content="Explore and influence how changes in work environments impact care needs and responsibilities."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper8.png"
              title="Empower Care Workers"
              content="Advocate for the rights, fair compensation, and recognition of care workers, aiming to elevate their status in the economy."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper9.png"
              title="Achieve Care Equity"
              content="Strive for equitable distribution of care responsibilities and access to care services, addressing gender disparities and socio-economic inequalities."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper10.png"
              title="Develop the Care Economy"
              content="Recognize and integrate the care economy as a vital part of Africa’s economic landscape, attracting investment and innovation."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper14.png"
              title="Enhance Healthcare Accessibility and Quality"
              content="Elevate health standards by expanding access to quality healthcare services, particularly in underserved regions, and emphasizing preventive health measures."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper15.png"
              title="Foster Sustainable Development and Climate Resilience"
              content="Promote sustainable practices and climate resilience as essential elements of the care economy, driving eco-friendly development and ensuring long-term societal and environmental health."
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CoreActivities;
