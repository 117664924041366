import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MHButton from "../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import MHFooter from "../components/Layout/MHFooter";

type Props = {};

const NotFoundPage = (props: Props) => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/`);
  };
  return (
    <React.Fragment>
      <Box className="relative">
        <img
          src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-caringblock-error.png"
          alt="Image"
          style={{}}
          className="object-cover h-fit w-full hidden md:hidden lg:block"
        />
        <img
          src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-caringblock-errorMob.png"
          alt="Image"
          style={{}}
          className="object-cover h-fit md:h-[600px] md:w-[100%] w-full block md:block lg:hidden"
        />

        <Box className="absolute items-center top-32 md:top-12 lg:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto">
          <Typography
            variant="h1"
            color="primary"
            fontSize={{ xs: "85.52px", sm: "100px" }}
            lineHeight={{ xs: "100%", sm: "90.5%" }}
            letterSpacing={"-0.06em"}
            className="capitalize  pt-32"
          >
            404 Error
          </Typography>
          <br />
          <br />

          <Typography
            variant="body2"
            color="primary"
            fontSize={{ xs: "13px", sm: "18px" }}
            lineHeight={{ xs: "185%", sm: "169%" }}
            letterSpacing={{ xs: "0.03em", sm: "0.02em" }}
            className={`-mt-7 md:w-[688px] w-[331px] mx-auto`}
          >
            Sorry, you've found a page we can't find.
          </Typography>

          <MHButton
            onClick={() => handleClickOpen()}
            className="my-9 capitalize text-[12px] leading-[120%] md:leading-[102%] tracking-[0.05em] w-[145px] md:w-[157px]  h-[46px] md:h-[52px] "
          >
            Take me home
          </MHButton>
        </Box>
      </Box>

      <MHFooter />
    </React.Fragment>
  );
};

export default NotFoundPage;
