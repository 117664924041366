import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { ReactComponent as FounderIcon } from "../../static/svg/article-colored.svg";

type Props = {};

const summitObj = [
  {
    caption: "Raise Awareness on the Importance of Care:    ",
    info: "Communicate the significance of valuing care and recognizing the contributions of those who provide care, emphasizing how it enables individuals to engage in various forms of work.     ",
  },
  {
    caption: "Introduce the Business Case for Care in Africa    ",
    info: "Present a compelling argument for investing in employee care, demonstrating the positive impact on productivity, employee satisfaction, and overall organizational success.    ",
  },
  {
    caption: "Highlight Current Landscape & Best Practices in Care:    ",
    info: "Showcase exemplary organizations that prioritize and implement effective care practices, offering valuable insights and inspiration to business, policymakers, and international organizations in attendance.      ",
  },
  {
    caption: "Engage Government and International Organizations     ",
    info: "Seek collaboration and support from policymakers, legislators, international organizations and non-governmental organizations to strengthen the impact of the care-focused initiatives and creation of a supportive legislative framwork in Nigeria.    ",
  },
  {
    caption: "Present The Economic Rationale for Investing in Care    ",
    info: "Explain how investing in the care sector can create jobs and stimulate economic growth. Reference studies or statistics that demonstrate the multiplier effect of such investment on the economy.    ",
  },
  // {
  //   caption: "Engage Policy Makers and Legislators:    ",
  //   info: "Raise awareness among policy makers and legislators about the benefits of policies supporting employee care, aiming to influence the creation of a supportive legislative framework.    ",
  // },
  // {
  //   caption: "Cultivate Support from International Organizations and NGOs:    ",
  //   info: "Seek collaboration and support from international organizations and non-governmental organizations to strengthen the impact of the care-focused initiatives in Nigeria.    ",
  // },
];

const summitAim = [
  {
    info: "Showcase FamTech Solutions: Present innovative technology solutions designed to support working parents and caregivers.",
  },
  {
    info: "Strengthen Resilience: Highlight the importance of building resilience and provide tools and strategies to do so in the workplace.",
  },
  {
    info: "Support Employee Resource Groups: Share effective strategies for nurturing and supporting Employee Resource Groups (ERGs).",
  },
  {
    info: "Highlight SMBs and Startups: Showcase how smaller businesses and startups are innovating in creating balanced workplaces.",
  },
  {
    info: "Foster Networking: Provide opportunities for attendees to connect, collaborate, and learn from one another, fostering a community dedicated to caring workplaces.",
  },
];

const SummitInfo = (props: Props) => {
  return (
    <Fragment>
      <section id="objectives" className="">
        {/* First */}
        <Box className=" lg:flex lg:justify-center lg:gap-10 mb-6">
          <Box className="order-last md:h-[630px] md:w-[630px] h-[343px] w-[343px] mx-auto lg:mx-0">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caw9.jpg"
              // src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-summit-info1.png"
              alt="Image"
              className=" rounded-[6px] object-cover md:mt-6 h-full"
            />
          </Box>
          <Box className="order-first justify-center text-center mx-auto lg:mx-0 w-[343px] md:h-[630px] md:w-[630px] md:mt-4 mt-10">
            <Box className="relative gap-0 md:mt-0 md:pb-16 lg:pb-0 " sx={{}}>
              {/* <img
                src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-summit-icon.png"
                alt="Icon"
                className="w-[29.6px] h-[29.6px] md:w-[43.3px] md:h-[43.3px] absolute md:top-0 left-24 md:left-[250px] lg:left-[250px]"
              /> */}

              <FounderIcon className="w-[24px] h-[24px] md:w-[30px] md:h-[30px] absolute md:top-0 left-20 md:left-[240px] lg:left-[240px]" />

              <Typography
                variant="caption"
                fontSize={{ xs: "11px", sm: "12px" }}
                lineHeight={{ xs: "230%", sm: "102%" }}
                letterSpacing={"0.1em"}
                className="uppercase text-center md:absolute md:top-4 mx-auto md:left-[270px]"
              >
                OUR FOUNDER
              </Typography>
            </Box>

            <Typography
              variant="h1"
              color="primary"
              fontSize={{ xs: "35px", sm: "43px" }}
              lineHeight={{ xs: "120%", sm: "120.5%" }}
              letterSpacing={"-0.06em"}
              className="lg:mt-10 w-[350px] mx-auto mt-4 md:mt-0 md:w-full"
            >
              BLESSING ADESIYAN
            </Typography>

            <Box className=" hidden md:flex my-0 place-content-center text-center">
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "13px", sm: "13px" }}
                lineHeight={{ xs: "247%", sm: "169%" }}
                letterSpacing={"0.02em"}
                className=" "
              >
                Founder & CEO MH WorkLife | Caring Africa
              </Typography>

              {/* <a
                onClick={() => {
                  window.open("https://www.caringafrica.org");
                }}
                className="cursor-pointer"
                target="_blanc"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "18px" }}
                  lineHeight={{ xs: "247%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-60 pl-2 underline"
                >
                  Caring Africa
                </Typography>
              </a> */}
            </Box>

            {/* Mobile */}

            <Box className="block md:hidden md:my-6 place-content-center text-center">
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "12px", sm: "18px" }}
                lineHeight={{ xs: "247%", sm: "169%" }}
                letterSpacing={"0.02em"}
                className=" "
              >
                Founder & CEO MH WorkLife | Caring Africa
              </Typography>

              {/* <a
                onClick={() => {
                  window.open("https://goo.gl/maps/UwSKaF2ni4xpBCAj9");
                }}
                className="cursor-pointer"
                target="_blanc"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "18px" }}
                  lineHeight={{ xs: "247%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-60 pl-2"
                >
                  Lagos, Nigeria
                </Typography>
              </a> */}
            </Box>

            {/* <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "16px", sm: "22px" }}
              lineHeight={{ xs: "140%", sm: "140%" }}
              letterSpacing={"0.02em"}
              className="w-[335px] md:w-[440px] mx-auto"
            >
              Caring Africa is dedicated to fostering a network of care
              advocates specific to the African context
            </Typography> */}
            <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[600px] w-[350px] pb-10 mx-auto text-center mt-6"
            >
              It is with great excitement and anticipation that I welcome you to
              the inaugural Caring Africa Summit, right here in the vibrant city
              of Lagos. This summit marks a pivotal moment in our collective
              journey to reshape the narrative around care in both work and life
              across Africa.
              <br />
              <br /> Our aim is bold yet clear: to harness data-driven insights
              and expand care infrastructures within workplaces and society at
              large. This approach is not just theoretical; it's a practical
              stride towards meaningful change. We stand at a crossroads where
              the needs of women, workplaces, and working families in Africa
              call for our urgent attention and action. The spectrum of care –
              encompassing childcare, aging care, employee care, and support for
              the ill and disabled, including domestic care workers – is no
              longer a 'nice-to-have'. It's an imperative. Together, we are
              poised to address these pressing challenges head-on, to catalyze
              the change that is not just desired but required for growth and
              prosperity across various sectors and industries in our workplaces
              and our beloved nations.
              <br />
              <br />
              This summit is more than a conference; it's a movement. A movement
              towards building a future where care is integrated seamlessly into
              the fabric of our professional and personal lives, enabling
              growth, equity, and well-being.
            </Typography>
          </Box>
        </Box>

        {/* Second */}
        <Box className=" lg:flex lg:justify-center lg:gap-10 md:pt-16 lg:pt-0">
          <Box
            className="order-first md:h-[650px] lg:h-[820px] md:w-[630px] h-[343px] w-[343px] mx-auto lg:mx-0 "
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"center"}
            display={"flex"}
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Attendees-2abq.png"
              alt="Image"
              className=" rounded-[6px] object-cover lg:ml-16"
            />
          </Box>
          <Box className="order-last text-center mx-auto lg:py-5 w-[343px] md:h-[800px] md:w-[700px] md:mt-8 mt-10 mb-10 ">
            <Typography
              variant="h1"
              color="primary"
              fontSize={{ xs: "26px", sm: "32px" }}
              lineHeight={{ xs: "143%", sm: "120.5%" }}
              letterSpacing={"-0.04em"}
              className="w-[302px] md:w-[560px] mx-auto text-center"
            >
              The Care Opportunity
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[500px] w-[350px] mx-auto mt-6"
            >
              The care opportunity in Africa is a cross-cutting catalyst for
              economic growth, driving success across economies and workplaces
              by creating jobs, empowering women and working families, and
              enhancing social, educational and health outcomes, ultimately
              laying the foundation for a more resilient and prosperous society.
              At the Summit, we hope to:
            </Typography>

            <Box
              // variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[650px] w-[340px] pb-10 mx-auto text-start mt-6"
            >
              {summitObj.map((obj, index) => (
                <Box className="flex gap-2 py-2">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" mx-auto text-start"
                    key={index}
                  >
                    <b className="font-areaNorm"> {obj.caption} </b>
                    {obj.info}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </section>
    </Fragment>
  );
};

export default SummitInfo;
