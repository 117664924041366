import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SpeakerCard from "./SpeakerCard";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import MHButton from "../Common/Button/MHButton";
import { filterDataBySlug, sortByCreatedAtAscending } from "../../utils/utils";
import { Typography } from "@mui/material";
import SpeakersMobile from "./SpeakersMobile";

type Props = {
  name?: string;
  company?: string;
  slug?: string;
  title?: string;
  imgSrc?: string;
};

const SummitSpeakers = (props: Props) => {
  const [noOfElement, setnoOfElement] = React.useState(8);
  const [summitSpeakerData, setSummitSpeakerData] = useState<Props[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const collectionRef = collection(db, "mh-summit-speakers-list");
        const collectionRef = collection(db, "caf-summit-speakers-list");
        const q = query(collectionRef, orderBy("createdAt", "asc"));

        const querySnapshot = await getDocs(q);

        const data = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as Props),
        }));

        const filteredData = filterDataBySlug(
          data,
          "caring-africa-nigeria-2024"
        );
        setSummitSpeakerData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Use the sorting function to sort the data
  const assortedData = sortByCreatedAtAscending(summitSpeakerData, "createdAt");

  const slice = assortedData.slice(0, noOfElement);
  const loadMore = () => {
    setnoOfElement(summitSpeakerData.length); //to view all at once
    // setnoOfElement(noOfElement + noOfElement); // to add more to the no of users on view
  };
  const loadLess = () => {
    setnoOfElement(8);
  };

  return (
    <React.Fragment>
      <section id="speakers" className="">
        <Box className="pt-8 hidden md:block">
          <Typography
            variant="h2"
            fontSize={{ xs: "38px", sm: "40px", md: "44px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            className="pt-10 pb-4 text-center "
          >
            {/* Confirmed Speakers */}
            Influential Voices In Our Ecosystem
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="text-center pb-16 w-[370px] md:w-[720px] mx-auto lg:w-[846px] lg:mx-auto md:flex justify-center"
          >
            Caring Africa has made a distinctive mark by uniting a dynamic mix
            of business leaders, legislators, policymakers, care innovators and
            investors. These stakeholders are the catalysts propelling
            innovative strides at home, in our workplaces, and society.
            {/* More speakers to be announced soon */}
          </Typography>
          <Grid
            container
            spacing={4}
            className="px-10 md:px-24 lg:px-20 lg:pl-20 py-4 place-content-center items-center"
          >
            {slice.map((speaker, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                <SpeakerCard
                  name={speaker.name}
                  company={speaker.company}
                  title={speaker.title}
                  imgSrc={speaker.imgSrc}
                />
              </Grid>
            ))}
          </Grid>

          <Box className=" mt-12 hidden md:flex place-content-center">
            <MHButton
              className=""
              onClick={() => {
                if (noOfElement < summitSpeakerData.length) {
                  loadMore();
                } else {
                  loadLess();
                }
              }}
              sx={{ width: "210px", height: "52px" }}
              color="primary"
            >
              {noOfElement < summitSpeakerData.length
                ? "View More"
                : "View Less"}
            </MHButton>
          </Box>

          <hr className="my-8 hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300" />
        </Box>
        <SpeakersMobile />
      </section>
    </React.Fragment>
  );
};

export default SummitSpeakers;
