import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { ReactComponent as LandingBgIcon } from "../../static/svg/landing.svg";
import { ReactComponent as LandingMobile } from "../../static/svg/landing-mobile.svg";
import { ReactComponent as SearchIcon } from "../../static/svg/search-icon.svg";
import { MHCustomInput } from "../Form/MHCustomInput";

import useInput from "../../hooks/use-input";
import * as validators from "../../utils/validators";
import MHButton from "../Common/Button/MHButton";
import MarqueeHorizontal, { MarqueeItemAlt } from "../UI/MarqueeHorizontal";
import MarqueeVertical from "../UI/MarqueeVertical";
import MHDropdown from "../Common/Dropdown/MHDropdown";
import { useHistory } from "react-router-dom";
import { LANDING_QUESTIONS_OPTIONS } from "../../utils/landing-data";
import StackedRow from "../UI/StackedRow";
// import MHDropdown from "../Dropdown/MHDropdown";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MHMenuItem } from "../Common/MenuPopper/MHMenu";

const MainView = () => {
  const {
    value: enteredCompanySize,
    valid: enteredCompanySizeIsValid,
    error: enteredCompanySizeHasError,
    onChange: companySizeInputChangeHandler,
    onBlur: companySizeInputBlurHandler,
    markAsTouched: markMerchantWebsiteInputAsTouched,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const [helpText, setHelpText] = React.useState("");
  const handleTitle = (event: SelectChangeEvent) => {
    setHelpText(event.target.value);
  };

  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/summit`);
  };

  const theme = useTheme();
  const matchesXlDown = useMediaQuery(theme.breakpoints.down("xl"));
  const matchesLgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const MENU_ITEMS: MHMenuItem[] = [
    {
      label:
        "How do I go about partnering with Caring Africa to support its initiatives?",
      onClick: () => {
        history.push("/about");
      },
    },
    {
      label:
        "How can I learn more about Caring Africa’s efforts to strengthen the care economy?",
      onClick: () => {
        history.push("/about");
      },
    },
    {
      label: "How do I find information on accessing quality childcare?",
      onClick: () => {
        history.push("/childcare");
        // window.open("https://app.motherhonestly.co/auth/leave-gap");
      },
    },
    {
      label: "How is Caring Africa amplifying disability care in Africa?",
      onClick: () => {
        history.push("/disability-care");
      },
    },
    {
      label: "How can I find out more about aging care in Africa?",
      onClick: () => {
        history.push("/aging-care");
      },
    },
  ];

  return (
    <React.Fragment>
      {/* TAB & WEB BEGINS */}

      {/* Icon and Colored BG */}
      {/* md:h-[493.5px] */}
      {/* md:top-[13%]  lg:top-[20%] */}
      <Box className="bg-sky-200 hidden lg:flex lg:h-[742px] overflow-hidden z-30 relative">
        <LandingBgIcon className="md:h-[450px] lg:h-full object-cover overflow-hidden" />
        <Box
          className="absolute md:left-[50px] lg:left-[82px] z-50"
          sx={{
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          {/* <Typography className="font-columbia md:text-[50px] lg:text-[88.30px] leading-[90.5%] tracking-[-0.065em] text-navy-900 capitalize">
            Flourish <br /> at Work & <br /> at home
          </Typography> */}
          <Typography
            variant="h2"
            fontSize={{ sm: "50px", md: "80px" }}
            lineHeight={"90.5%"}
            letterSpacing={"-0.06em"}
            className="capitalize"
          >
            Care Is <br /> The Catalyst <br /> For Africa’s <br /> Economic
            Growth
          </Typography>

          <Typography
            variant="subtitle2"
            fontSize={{ sm: "13px", md: "18px" }}
            lineHeight={"169%"}
            letterSpacing={"0.02em"}
            className="md:py-7 lg:py-[50px] lg:w-[40%] md:w-[35%]"
          >
            We all rely on care - from childcare to aging care. It’s the
            underpinning of our economies, and it’s time we invest in care to
            expand our economic potential & transform the way we live and work.
          </Typography>

          <Box className="flex items-center" position="relative" zIndex={10}>
            <Box className="bg-white flex md:h-[70px] lg:h-[86px] md:w-[150px] lg:w-[186px] md:py-6 lg:py-8 md:px-5 lg:px-7 drop-shadow-sm">
              <SearchIcon />

              <Typography
                variant="subtitle2"
                fontSize={{ sm: "10px", md: "12px" }}
                lineHeight={"195%"}
                letterSpacing={"0.06em"}
                className="ml-2 "
              >
                I'd like to know
              </Typography>
            </Box>

            {/* md:h-[28px] */}
            <Box className="bg-lilac-300 z-10 items-center flex md:h-[68px] lg:h-[84px] md:w-[510px] lg:w-[559px] py-5 lg:px-2">
              <Box
                pr={4}
                className="md:w-[340px] lg:w-[340px] md:ml-4 lg:mr-2 text-navy-900"
                sx={{
                  "& .MuiTypography-root": {
                    lineHeight: "195%",
                    fontSize: { sm: "10px", md: "12px" },
                    letterSpacing: "0.06em",
                    fontFamily: "Area-Normal-Bold",
                  },
                }}
              >
                <MHDropdown label="Select Help" items={MENU_ITEMS} />
                {/* <FormControl variant="standard" sx={{ minWidth: '100%' }}>
                  <Select
                    value={helpText}
                    onChange={handleTitle}
                    displayEmpty
                    // inputProps={{ "aria-label": "Without label" }}
                    className="bg-lilac-300 text-navy-900 font-areaBold"
                    disableUnderline
                    sx={{ fontSize: { sm: 12, md: 13 } }}
                    label="Age"
                    MenuProps={{
                      style: {
                        fontFamily: 'Area-Normal-Semibold',
                        fontSize: '10px',
                        fontWeight: 600
                      }
                    }}>
                    <MenuItem value="" disabled>
                      Select Help
                    </MenuItem>
                    {LANDING_QUESTIONS_OPTIONS.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Box>
              <MHButton
                onClick={() => handleClickOpen()}
                className="md:text-[12px] lg:text-[12px] leading-[195%] tracking-[0.06em] md:w-[160px] lg:w-[167px] md:h-[50px] lg:h-[52px] py-5"
              >
                Learn More
              </MHButton>
            </Box>
          </Box>
        </Box>

        {/* Image at the Right */}
        <Box
          className="md:w-[1200px] lg:w-[63.5%] overflow-hidden"
          position="relative"
          sx={{
            "& > img": {
              objectFit: "cover",
              objectPosition: "center",
              ...(matchesXlDown && {
                width: "100%",
              }),
            },
          }}
        >
          <img
            src={
              matchesXlDown
                ? matchesLgDown
                  ? "https://res.cloudinary.com/mother-honestly/image/upload/v1692224563/Vector_2_kii5dx.png"
                  : "https://res.cloudinary.com/mother-honestly/image/upload/v1692224016/Vector_1_xujm4i.png"
                : "https://res.cloudinary.com/mother-honestly/image/upload/v1670494585/start_eqpw0y.png"
            }
            alt="Hero"
          />

          {/* md:h-[440px] lg:h-[740px] w-[180px] right-0 */}
          <Box className="w-full absolute rotate-90 topair md:top-[9%] lg:top-[40%] md:left-[39%] overflow-hidden overscroll-y-none gap-4">
            <MarqueeHorizontal continuous>
              <MarqueeItemAlt>
                {/* <Typography className=" rotate-90 font-columbia text-white md:text-[150px] lg:text-[200px] leading-[101%] tracking-[0.01em]">
                  CAREER & CARE
                </Typography> */}
                <Typography
                  variant="h2"
                  fontSize={{ sm: "150px", md: "200px" }}
                  lineHeight={"101%"}
                  letterSpacing={"0.01em"}
                  textTransform="uppercase"
                  color={"white"}
                >
                  Career Caregiving Wellbeing Finances Lifestyle
                </Typography>
              </MarqueeItemAlt>
              {/* <MarqueeList>
                <Typography
                  variant="h2"
                  fontSize={{ sm: '150px', md: '200px' }}
                  lineHeight={'101%'}
                  letterSpacing={'0.01em'}
                  textTransform="uppercase"
                  color={'white'}
                  className="rotate-90">
                  Typography
                </Typography>
              </MarqueeList>
              <MarqueeList>
                <Typography
                  variant="h2"
                  fontSize={{ sm: '150px', md: '200px' }}
                  lineHeight={'101%'}
                  letterSpacing={'0.01em'}
                  textTransform="uppercase"
                  color={'white'}
                  className="rotate-90">
                  Typography
                </Typography>
              </MarqueeList>
              <MarqueeList>
                <Typography
                  variant="h2"
                  fontSize={{ sm: '150px', md: '200px' }}
                  lineHeight={'101%'}
                  letterSpacing={'0.01em'}
                  textTransform="uppercase"
                  color={'white'}
                  className="rotate-90">
                  Typography
                </Typography>
              </MarqueeList>
              <MarqueeList>
                <Typography
                  variant="h2"
                  fontSize={{ sm: '150px', md: '200px' }}
                  lineHeight={'101%'}
                  letterSpacing={'0.01em'}
                  textTransform="uppercase"
                  color={'white'}
                  className="rotate-90">
                  Typography
                </Typography>
              </MarqueeList>
              <MarqueeList>
                <Typography
                  variant="h2"
                  fontSize={{ sm: '150px', md: '200px' }}
                  lineHeight={'101%'}
                  letterSpacing={'0.01em'}
                  textTransform="uppercase"
                  color={'white'}
                  className="rotate-90">
                  Typography
                </Typography>
              </MarqueeList> */}
            </MarqueeHorizontal>
          </Box>
        </Box>
      </Box>

      {/* TAB & WEB ENDS */}

      {/* MOBILE BEGINS */}
      <Box className="bg-sky-200 lg:hidden block h-[850px] relative">
        <LandingMobile className="h-full object-cover" width="100%" />

        <Box className="w-full absolute top-[6%]" zIndex={10}>
          <Box className=" text-center">
            <Typography
              variant="h2"
              fontSize={{ xs: "45px" }}
              lineHeight={"90%"}
              letterSpacing={"-0.05em"}
              className=" capitalize"
            >
              {/* Proactive Care For <br /> Work And Life */}
              Care Is <br /> The Catalyst
              <br />
              For Africa’s <br /> Economic Growth
            </Typography>

            <Typography
              variant="subtitle2"
              fontSize={{ xs: "13px" }}
              lineHeight={"169%"}
              letterSpacing={"0.02em"}
              className="py-5 w-[90%] mx-auto"
            >
              {/* Join a community of parents, caregivers, and employers seeking
              sustainable solutions to optimal wellbeing at home and in the
              workplace. */}
              {/* From tackling the relentless demands of care to its associated
              cost, we provide solutions to support all employees – parents,
              caregivers, or others – in achieving optimal wellbeing. */}
              We all rely on care - from childcare to aging care. It’s the
              underpinning of our economies, and it’s time we invest in care to
              expand our economic potential & transform the way we live and
              work.
            </Typography>
          </Box>

          <Box width={343} mx="auto">
            <Box className="bg-white flex h-[58px] px-5 py-5 items-center place-content-center mx-auto">
              <SearchIcon />
              {/* <Typography className="ml-2 font-areaBold text-[10px]  leading-[180%] tracking-[0.05em] text-navy-900 ">
              I'd like to know
            </Typography> */}
              <Typography
                variant="subtitle2"
                fontSize={{ xs: "12px" }}
                lineHeight={"180%"}
                letterSpacing={"0.05em"}
                className="ml-2 "
              >
                I'd like to know
              </Typography>
            </Box>

            <StackedRow
              bgcolor="#F3F4F6"
              alignItems="center"
              justifyContent="center"
              py={2}
            >
              <MHDropdown
                label="Select Help"
                menuContainerSx={{
                  width: 390,
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  ml: 0,
                }}
                items={MENU_ITEMS}
              />
            </StackedRow>

            {/* <Box className="bg-lilac-300 w-[343px] h-[58px] pl-3 py-2 text-navy-900 items-center place-content-center mx-auto">
             <FormControl variant="standard" sx={{ minWidth: '100%' }}>
              <Select
                value={helpText}
                onChange={handleTitle}
                displayEmpty
                // inputProps={{ "aria-label": "Without label" }}
                className="bg-lilac-300 text-navy-900 font-areaBold"
                disableUnderline
                sx={{ fontSize: '12px' }}
                label="Age"
                MenuProps={{
                  style: {
                    fontFamily: 'Area-Normal-Semibold',
                    fontSize: '12px',
                    fontWeight: 600
                  }
                }}>
                <MenuItem value="" disabled>
                  Select Help
                </MenuItem>
                {LANDING_QUESTIONS_OPTIONS.map((item, index) => (
                  <MenuItem
                    value={item.value}
                    key={index}
                    sx={{ fontSize: '12px' }}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> 
            </Box> */}

            <Box className=" w-[343px] h-[58px] items-center place-content-center mx-auto">
              <MHButton
                onClick={() => handleClickOpen()}
                className="text-[12px] leading-[102%] tracking-[0.005em] w-[343px] h-[58px] py-5"
              >
                Learn More
              </MHButton>
            </Box>
          </Box>
        </Box>

        {/* Image Below */}

        <Box className="w-full absolute bottom-0 overflow-hidden">
          <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1670506517/start-mobile_yztxzl.png"
            alt="Landing"
            className="md:w-full h-96 mt-30 md:h-[500px] object-cover md:object-cover"
          />
        </Box>
        <Box className="w-full h-fit absolute bottom-0 z-30">
          <MarqueeHorizontal continuous>
            <MarqueeItemAlt>
              {/* <Typography className="font-columbia text-white text-[85.53px] leading-[100%] tracking-[-0.006em]">
                CAREER & CARE
              </Typography> */}
              <Typography
                variant="h2"
                fontSize={{ xs: "85.53px" }}
                lineHeight={"100%"}
                letterSpacing={"-0.06em"}
                textTransform="uppercase"
                color={"white"}
              >
                Career Caregiving Wellbeing Finances Lifestyle
              </Typography>
            </MarqueeItemAlt>
            {/* <MarqueeList>
              <Typography
                variant="h2"
                fontSize={{ xs: '85.53px' }}
                lineHeight={'100%'}
                letterSpacing={'-0.06em'}
                color={'white'}>
                CAREER & CARE
              </Typography>
            </MarqueeList>
            <MarqueeList>
              <Typography
                variant="h2"
                fontSize={{ xs: '85.53px' }}
                lineHeight={'100%'}
                letterSpacing={'-0.06em'}
                color={'white'}>
                CAREER & CARE
              </Typography>
            </MarqueeList> */}
          </MarqueeHorizontal>
        </Box>
      </Box>

      {/* MOBILE ENDS */}
    </React.Fragment>
  );
};

export default MainView;
