import React from "react";
import Box from "@mui/material/Box";

import Consultants from "./Consultants";
import Content from "./Content";
import Employers from "./Employers";
import Membership from "./Membership";
import SummitAndEvent from "./SummitAndEvent";

type Props = {};

const FAQBody = (props: Props) => {
  return (
    <React.Fragment>
      <Box
        data-bs-spy="scroll"
        data-bs-target="#scrollspy1"
        data-bs-offset="100"
        className="scrollspy-example h-[1000px] mb-10 md:overflow-y-auto overflow-x-hidden md:w-[500px] lg:w-[847px]"
      >
        <Membership />
        <Content />
        <SummitAndEvent />
        <Employers />
        <Consultants />
      </Box>
    </React.Fragment>
  );
};

export default FAQBody;
