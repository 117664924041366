import React, { Fragment } from "react";
import AllresHeader from "../Resources/SubComponents/AllresHeader";

import { ReactComponent as BgOverlay } from "../../static/svg/event.svg";
import SummitHeaderCard from "./SummitHeaderCard";
import SponsorDialog from "../PastSummit/SponsorDialog";
import InRegistrationDialog from "./InRegistrationDialog";

type Props = {};

const SummitHeader = (props: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <SummitHeaderCard
        headerClassName="relative text-center pl-0 mx-auto h-fit md:h-[600px] lg:h-[600px] w-full bg-[#194049] overflow-hidden"
        majorClassName="md:absolute pt-12 md:top-12 md:inset-x-[10%] lg:top-12 lg:inset-x-[60%] text-center place-content-center"
        boxClassName=" pb-6 pl-32 md:pl-0 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        podClassName="hidden"
        title="Summit"
        titleInfo="Caring Africa Summit"
        titleExt="February 23, 2024 | 9:30am WAT"
        titleLocation="Alliance Francaise, Ikoyi, Lagos        "
        titleInfoclassName="md:text-left absolute left-8 md:left-0 md:relative justify-center mb-6 w-[80%] md:w-[460px] lg:w-[400px] font-columbia text-[38px] capitalize font-[500]"
        pageInfo="Elevating Care, Transforming Workplaces, and Strengthening Economies.        "
        pageInfoClassName="capitalize font-semibold mt-56 md:mt-0 md:pl-0 md:text-left w-[70%] md:ml-0 md:w-[540px] lg:w-[440px] text-[16px] md:text-[22px] leading-[140%] font-areaSemi"
        ResIconUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/res-summit-icon.png"
        BgUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-afri-header2.png"
        BgMobUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/caf-afri-headerMob.png"
        onClickReg={() => {
          window.open(
            "https://events.ringcentral.com/events/caring-africa-summit/registration"
          );
        }}
        // onClickVideo={() => {
        //   window.open(
        //     "https://mh-resources-production.s3.us-west-1.amazonaws.com/New-Care-At-Work-Summit-Proposal.pdf"
        //   );
        // }}
        onClickVideo={() => {
          // window.open(
          //   "https://mh-resources-production.s3.us-west-1.amazonaws.com/New-Care-At-Work-Summit-Proposal.pdf"
          // );
          handleOpen();
        }}
      >
        <p className="hidden">hello</p>
        <section id="registration"></section>
      </SummitHeaderCard>

      <InRegistrationDialog open={open} onClose={handleClose} />
    </Fragment>
  );
};

export default SummitHeader;
