import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FAQ_HeaderData } from "../../utils/faq-data";

type Props = {};

const FAQSideBar = (props: Props) => {
  const [active, setActive] = React.useState("membership");

  return (
    <React.Fragment>
      <Box id="scrollspy1" className="sticky top-0 overflow-hidden">
        <ul className="nav nav-pills sticky flex md:grid overscroll-x-none overflow-auto">
          {FAQ_HeaderData.map((headerData, index) => (
            <li className="mb-3 mt-6">
              <a
                className="nav-link no-underline "
                href={`#${headerData.linkTo}`}
                onClick={() => {
                  setActive(headerData.linkTo);
                }}
              >
                <Typography
                  variant={`${
                    active === headerData.linkTo ? "subtitle1" : "subtitle2"
                  }`}
                  color="primary"
                  fontSize={{ xs: "12px", sm: "12px" }}
                  lineHeight={{ xs: "120%", sm: "102%" }}
                  letterSpacing={"0.05em"}
                  className=" capitalize h-fit flex w-[130px] "
                  justifyContent={{ xs: "center", sm: "start" }}
                >
                  {headerData.title}
                  <Typography
                    className="px-1 -mt-1"
                    sx={{
                      fontWeight: 600,
                      fontSize: "10px",
                      fontFamily: "Area-Normal-Semibold",
                    }}
                  >
                    {headerData.count}
                  </Typography>
                </Typography>

                {active === headerData.linkTo ? (
                  <Box className="bg-[#194049] h-[1px] w-[35%] ml-8 md:w-[20%] lg:w-[20%] md:ml-0 md:mx-auto"></Box>
                ) : null}
              </a>
            </li>
          ))}
        </ul>
      </Box>
    </React.Fragment>
  );
};

export default FAQSideBar;
