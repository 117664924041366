import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const AboutPartnership = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "11px", sm: "12px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          What is MH?
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          MH WorkLife is a care ecosystem designed to support parents,
          caregivers, and employees of ALL kinds in managing their personal and
          professional lives through community, resources, education and
          providing avenues for the five elements of well-being - career,
          financial, social, physical, and community wellbeing. We reach over
          25M+ audience annually of vetted professionals, thought-leaders, CEOs,
          and more through our various platform channels conducting bespoke and
          off-the-shelf programs ranging from special member benefits,
          custom-tailored education about your products or services, digital and
          IRL conferences, summits, and workshops designed to elevate care.
        </Typography>

        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "30px", md: "30px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize mt-20 mb-6 w-[311px] md:w-[700px] text-center mx-auto"
        >
          MH Expands Your Reach And Access With A Community Caring Employers,
          Brands, And Care Innovators. Including Working Parents, Caregivers,
          Blacks, Latinos, And Employees Of All Kinds
        </Typography>
      </Box>

      <Box className=" py-12 mx-auto grid place-content-center lg:flex lg:justify-center lg:gap-10 ">
        <Box>
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Employers.png"
            alt=""
            className="w-[235px] h-[235px] md:w-[400px] md:h-[400px] lg:w-[401px] lg:h-[401px] rounded-[6px] my-4"
          />
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: "43px", sm: "43px", md: "43px" }}
            lineHeight={{ xs: "120%", sm: "120.5%" }}
            letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
            className="capitalize w-[230px] md:w-[301px] text-center mx-auto"
          >
            Employers
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "180%", sm: "195%" }}
            letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
            className=" w-[230px] md:w-[280px] pt-4 text-center mx-auto"
          >
            We partner with employers to support their employee resource groups,
            parents, caregivers, and build community, and foster connections.
          </Typography>
        </Box>
        <Box>
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Brands.png"
            alt=""
            className="w-[235px] h-[235px] md:w-[400px] md:h-[400px] lg:w-[401px] lg:h-[401px] rounded-[6px] my-4"
          />
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: "43px", sm: "43px", md: "43px" }}
            lineHeight={{ xs: "120%", sm: "120.5%" }}
            letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
            className="capitalize w-[230px] md:w-[301px] text-center mx-auto"
          >
            Brands
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "180%", sm: "195%" }}
            letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
            className=" w-[230px] md:w-[301px] pt-4 text-center mx-auto"
          >
            We help brands position themselves as caring brands, building trust
            and strengthening their connection to our immediate and extended
            communities.
          </Typography>
        </Box>
        <Box>
          <img
            src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Care-Innovators.png"
            alt=""
            className="w-[235px] h-[235px] md:w-[400px] md:h-[400px] lg:w-[401px] lg:h-[401px] rounded-[6px] my-4"
          />
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: "43px", sm: "43px", md: "43px" }}
            lineHeight={{ xs: "120%", sm: "120.5%" }}
            letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
            className="capitalize w-[230px] md:w-[301px] text-center mx-auto"
          >
            Care Innovators
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "180%", sm: "195%" }}
            letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
            className=" w-[230px] md:w-[301px] pt-4 text-center mx-auto"
          >
            We help innovators who are building care and family technology
            connect authentically and intentionally with parents, caregivers,
            and employers within our ecosystem.
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default AboutPartnership;
