import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { PartnershipCard } from "../Equity/PartnershipCard";

type Props = {};

const ProgramsAndObjectives = (props: Props) => {
  return (
    <Fragment>
      <Box className="bg-lilac-300 overflow-hidden pb-10">
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "40px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize pt-10 pb-6 md:w-[594px] w-[298px] text-center mx-auto"
        >
          {/* Our Core Programs And Objectives */}
          Objectives
        </Typography>
        <Grid
          container
          spacing={0}
          className="lg:ml-5 lg:px-12 md:gap-4 lg:gap-0 md:mx-auto md:place-content-center"
        >
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper1.png"
              title="Raise Public Awareness and Educate"
              content="Increase awareness about the interconnectedness of care, economic growth, and gender equity, highlighting the transformative potential of an equitable care ecosystem."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper2.png"
              title="Empower Women in Business and Politics"
              content="Facilitate initiatives that bridge the care gap, enabling more women to engage in and lead scalable businesses and political activities."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper3.png"
              title="Advocate for Informed Care Policies"
              content="Conduct research and advocate for policies that recognize the care sector's economic significance and its role in closing the gender equity gap."
            />
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper4.png"
              title="Educate and Engage Investors"
              content="Educate and engage with investors to showcase the opportunities within the care economy, emphasizing its viability and potential for growth."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper5.png"
              title="Develop Care Infrastructure"
              content="Develop and improve care infrastructure, making it a cornerstone for economic development and societal well-being."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/prosper6.png"
              title="Formalize and Support Care Work"
              content="Work towards formalizing care work, ensuring fair compensation and working conditions, and elevating its status within the economy."
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ProgramsAndObjectives;
