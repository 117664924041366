import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography, { typographyClasses } from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import Membership from "../components/Features/Landing/MembershipWidget";
import MembershipPlans from "../components/Features/Memberships/MembershipPlans";
import MembershipFeatures from "../components/Features/Memberships/MembershipFeatures";

import Divider from "@mui/material/Divider";

import FollowMH from "../components/Landing/FollowMH";
import MHFooter from "../components/Layout/MHFooter";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHButton from "../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import EnterprisePlan from "../components/Landing/EnterprisePlan";
import PlatformFeatures from "../components/Features/Memberships/PlatformFeatures";
import { MagazineFeature } from "./Employers";
import StackedRow from "../components/UI/StackedRow";
import ImageGrid from "../components/Story/ImageGrid";

const AgingCare = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  const membershipPlansDivRef = React.useRef<{
    divEl: HTMLDivElement | null;
  }>({ divEl: null });

  const vision = [
    {
      caption: "Advocacy for Robust Care Policies: ",
      content:
        "We work to influence policy changes that recognize the evolving needs of the aging population.      ",
    },
    {
      caption: "Supporting Caregiver Training: ",
      content:
        "By offering training and resources to caregivers, we aim to enhance the quality of care provided to older adults. ",
    },
    {
      caption: "Building Community Partnerships: ",
      content:
        "Collaborating with local communities, we strive to establish support systems that integrate traditional family roles with modern care solutions.        ",
    },
    {
      caption: "Investing in Aging Research:  ",
      content:
        "Conducting research on aging and healthcare needs to inform effective strategies for elder care in Africa.",
    },
  ];

  return (
    <React.Fragment>
      <Grid container direction={{ xs: "column", md: "row" }} mb={4}>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={500}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              "& > p": {
                textAlign: "center",
                pointerEvents: "none",
              },
            }}
          >
            <Typography
              variant="caption"
              textTransform="uppercase"
              fontSize="11px"
              letterSpacing="0.1em"
              lineHeight="230%"
              sx={{
                mb: 5,
              }}
              paragraph
            >
              Aging Care
            </Typography>

            <Typography
              variant="h1"
              fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
              textTransform="capitalize"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Join Us in Supporting Older Adults
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              fontSize="13px"
              lineHeight="185%"
              letterSpacing="0.02em"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              The journey of aging should be one of dignity and respect. By
              supporting Caring Africa, you contribute to a future where the
              elderly in Africa are cared for, valued, and continue to be
              integral members of our societies. Together, we can make a
              difference in the lives of older adults across Africa.
            </Typography>

            {/* <MHButton
              sx={{
                width: "fit-content",
              }}
              onClick={() =>
                membershipPlansDivRef.current?.divEl?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              More Info
            </MHButton> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              "& > img": {
                height: 600,
                width: "100%",
                objectPosition: "bottom",
                objectFit: "cover",
              },
            }}
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/addprosper4.jpg"
              alt="Banner"
              width="700"
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        py={7}
        px={{ xs: 2, md: 6, lg: 8 }}
        color="primary.main"
        sx={{
          [`& > .${typographyClasses.root}`]: {
            // color: '#EDD5B1',
            textAlign: "center",
          },
        }}
      >
        {/* <Typography
          variant="caption"
          fontSize="12px"
          lineHeight="102%;"
          letterSpacing="0.1em"
          paragraph
        >
          childcare
        </Typography> */}

        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          Navigating the Path of Aging with Dignity: Transforming Aging Care in
          Africa
        </Typography>
      </Box>

      {/* <Divider
        variant="middle"
        sx={{
          borderColor: "primary.main",
          width: "91%",
          mx: "auto",
          opacity: 0.1,
          my: 8,
        }}
      /> */}
      <ImageGrid
        title="The Rising Tide of Aging        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/agingcare1.png"
        content="The landscape of aging in Africa is changing rapidly, with the older population projected to more than triple from 74.4 million in 2020 to 235.1 million by 2050​​. Nigeria alone, with its significant older population, exemplifies the continental shift towards an aging society. This demographic shift brings with it an increased demand for long-term care services and highlights the urgent need for strategic, culturally sensitive solutions that involve governments, NGOs, and communities.        "
        content2="hidden"
      />
      <ImageGrid
        title="The Role of Families and Challenges Ahead        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last lg:order-first"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/agingcare3.png"
        content="Traditionally in Africa, family members have been the primary caregivers for older adults, a practice deeply rooted in cultural norms​​. However, this reliance on family care is facing challenges due to urbanization, financial constraints, and changing family structures. The burdens placed on family caregivers, predominantly women and girls, are immense, often leading to inadequate care quality and significant personal costs​​.        "
        content2="hidden"
      />
      <ImageGrid
        title="Innovating Care Solutions        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/agingcare2.png"
        content="Recognizing these challenges, there is a growing consensus that organized, professional long-term care services are necessary to supplement family caregiving​​. In countries like Mauritius, Seychelles, and South Africa, efforts to establish comprehensive care systems are underway, offering valuable lessons for other African nations.        "
        content2="hidden"
      />

      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          Our Vision at Caring Africa
        </Typography>

        <Typography
          variant="body2"
          fontSize={{ xs: "13px", md: "18px" }}
          lineHeight={{ xs: "200%", md: "169%" }}
          letterSpacing="0.02em"
          width={{ xs: "90%", md: "60%" }}
          mx="auto"
          mb={4}
        >
          At Caring Africa, we are committed to transforming aging care in
          Africa. We envision a future where older adults receive the care they
          need in environments that respect their dignity and contributions to
          society. Our approach involves:
        </Typography>

        {/* Web */}
        <Box className=" hidden md:block mb-20">
          <Grid container spacing={6} className=" mx-auto place-content-center">
            {vision.map((obj, index) => (
              <Grid item xs={12} sm={5} md={5} lg={5}>
                <Box className="flex gap-2 py-2 w-4/6 ">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" text-start"
                    key={index}
                  >
                    <b className="font-areaNorm"> {obj.caption} </b>
                    {obj.content}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Mobile */}
        <Box
          // variant="body2"
          color="primary"
          fontSize={{ xs: "13px", sm: "14px" }}
          lineHeight={{ xs: "185%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="md:w-[600px] w-[340px] block md:hidden pb-10 mx-auto text-start mt-6 px-4"
        >
          {vision.map((obj, index) => (
            <Box className="flex gap-2 py-2">
              •
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "13px", sm: "14px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className=" text-start"
                key={index}
              >
                <b className="font-areaNorm"> {obj.caption} </b>
                {obj.content}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <MHSubscribe />

      <MHFooter />
    </React.Fragment>
  );
};

export default AgingCare;
