import * as React from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import NavPrimaryLinks from "./NavPrimaryLinks";
import MHLogo from "../../theme/icons/MHLogo";

import { ReactComponent as SearchIcon } from "../../static/svg/search-icon.svg";
import { ReactComponent as MenuIcon } from "../../static/svg/menu.svg";
import { ReactComponent as CloseIcon } from "../../static/svg/close.svg";
import { ReactComponent as CartIcon } from "../../static/svg/cart.svg";
import StaticDataContext from "../../store/context/static-data.context";
import AbsolutePositionedContainer from "../UI/AbsolutePositionedContainer";
import IconButtonStyled from "../Common/Button/IconButtonStyled";
import MobileSidebar, {
  GuidedSessionsDrawerDropdownContent,
  MobileSidebarLink,
  ResourcesDrawerDropdownContent,
  SummitDrawerDropdownContent,
} from "./MobileSidebar";
import useDrawer from "../../hooks/use-drawer";
import { DRAWER_WIDTH } from "../../utils/constants";
import { DropdownButton } from "../Common/Dropdown/MHDropdown";
import useDialog from "../../hooks/use-dialog";
import SearchPopout from "./SearchPopout";
import MobileSearchPopout from "./MobileSearchPopout";
import StackedIconWithText from "../UI/StackedIconWithText";
import { ReactComponent as UserIcon } from "../../static/svg/user.svg";
import AuthContext from "../../store/context/auth-context";
import MHMenu, { MHMenuItem } from "../Common/MenuPopper/MHMenu";
import useToggle from "../../hooks/use-toggle";
import MHPopper from "../Common/MenuPopper/MHPopper";
import usePopper from "../../hooks/use-popper";
import Ping from "../UI/Ping";
import RoundedLogoIcon from "../../theme/icons/RoundedLogo";
import CartMenu from "./CartMenu";
import CartContext from "../../store/context/cart-context";
import StackedRow from "../UI/StackedRow";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1000,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const typographyStyles: any = {
  color: "#194049",
  fontSize: 10,
  fontFamily: "Area-Normal-Black",
  lineHeight: "92%",
  textAlign: "center",
  letterSpacing: "0.115em",
  textTransform: "uppercase",
};

const NavBarLink = styled("li")(({ theme }) => ({
  listStyle: "none",
  listStyleType: "none",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  ...typographyStyles,
}));

const NavAction = styled("div")<{ show?: boolean }>(({ theme, show }) => ({
  backgroundColor: "#C7D8E4",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  height: 64,
  cursor: "pointer",
  width: show ? DRAWER_WIDTH : 300,
  ...(!show && {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }),
  ...typographyStyles,
}));

const AppBar = () => {
  const theme = useTheme();
  const { path } = useRouteMatch();
  const matchesLgDown = useMediaQuery(theme.breakpoints.down(1380));

  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataCtx = React.useContext(StaticDataContext);
  const { handleOpen, handleOpenDemoRequest } = dataCtx;

  const { open: isDrawerOpen, anchor, toggleDrawer } = useDrawer("left", false);
  const {
    openDialog: isSearchOpen,
    handleOpenDialog: handleOpenSearch,
    handleCloseDialog: handleCloseSearch,
  } = useDialog();

  const { open: openToggleMenu, handleToggle } = useToggle();

  const { open: openSessionsToggleMenu, handleToggle: handleToggleSessions } =
    useToggle();
  const { open: openSummitToggleMenu, handleToggle: handleToggleSummit } =
    useToggle();

  const DROPDOWN_SX = {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    mb: "36px",
    "& > p": {
      fontFamily: "Area-Normal-Bold !important",
      textDecoration: "none",
      fontSize: ".95rem",
      lineHeight: "92%",
      textTransform: "capitalize",
    },
    // '& > svg': {
    //   width: '.9rem',
    //   height: '1.1rem'
    // }
  };

  const history = useHistory();

  const { user, auxUserDetails, isAuthenticated, logout } =
    React.useContext(AuthContext);

  const MENU_ITEMS: MHMenuItem[] = [
    ...(auxUserDetails?.subscriptionStatus === "active"
      ? [
          {
            label: "Dashboard",
            onClick: () => {
              window.open("https://employee-dashboard-v1-s757.vercel.app/");
            },
          },
          {
            label: "Settings",
            onClick: () => {
              window.open(
                "https://employee-dashboard-v1-s757.vercel.app/settings/profile"
              );
            },
          },
        ]
      : []),
    {
      label: "Logout",
      onClick: logout,
    },
  ];

  const { popperAnchorEl, openPopper, handleOpenPopper, handleClosePopper } =
    usePopper();

  const cartCtx = React.useContext(CartContext);
  const { cart, addItemToCart, removeItemFromCart, closeCartDrawer } = cartCtx;

  React.useEffect(() => {
    // close sidebar when route changes
    history.listen((location, action) => {
      // console.log(location);
      // console.log(action);
      toggleDrawer(false)({} as React.MouseEvent<HTMLDivElement>);
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBarStyled
        position="static"
        open={true}
        sx={{
          paddingBlock: 0,
        }}
      >
        <StackedRow
          bgcolor="#ECEDF9"
          height={30}
          spacing={3}
          alignItems="center"
          px={3}
          sx={{
            justifyContent: { xs: "center", sm: "space-between" },
          }}
        >
          {/* {!matchesMdDown && (
            <MHPopper
              open={openPopper}
              anchorEl={popperAnchorEl}
              popperContent={<CartMenu />}
              onClickAway={handleClosePopper}
              sx={{
                width: '400px'
              }}>
              <IconButtonStyled
                aria-label="cart-button"
                sx={{
                  position: 'relative'
                }}
                onClick={handleOpenPopper}>
                <CartIcon title="cart" color="#194049" width="1rem" />
                {cart.length > 0 && (
                  <AbsolutePositionedContainer
                    sx={{
                      top: 0,
                      right: 0
                    }}
                    defaultPos>
                    <RoundedLogoIcon
                      sx={{
                        width: '7px',
                        height: '7px',
                        background: '#ff1744'
                      }}></RoundedLogoIcon>
                  </AbsolutePositionedContainer>
                )}
              </IconButtonStyled>
            </MHPopper>
          )} */}

          {/* <NavBarLink onClick={() => history.push('/employers')}>
            Brokers
          </NavBarLink> */}
          <StackedRow
            height={"100%"}
            spacing={3}
            alignItems="center"
            display={{ xs: "none", sm: "flex" }}
          >
            <NavBarLink onClick={() => window.open("https://thecaregap.com")}>
              The Care Gap
            </NavBarLink>

            <NavBarLink onClick={() => history.push("/")}>
              Caring Africa
            </NavBarLink>

            <NavBarLink onClick={() => window.open("https://caringblocks.com")}>
              Caring Blocks
            </NavBarLink>
            {/* <NavBarLink onClick={() => history.push("/equity")}>
              Equity
            </NavBarLink>
            <NavBarLink onClick={() => history.push("/find-care")}>
              The Care Gap
            </NavBarLink> */}
          </StackedRow>

          <StackedRow
            height={"100%"}
            spacing={3}
            justifyContent="flex-end"
            alignItems="center"
            display={{ xs: "none", sm: "flex" }}
          >
            {/* <NavBarLink onClick={() => history.push("/partner")}>
              Partner
            </NavBarLink> */}

            {/* {isAuthenticated && user ? null : (
              <NavBarLink onClick={() => history.push("/auth/subscribe")}>
                Sign Up
              </NavBarLink>
            )}

            {isAuthenticated && user ? (
              <MHMenu items={MENU_ITEMS} onMenuToggle={(open) => {}}>
                <StackedIconWithText
                  spacing={1}
                  id="profile-button"
                  aria-controls={"profile-menu"}
                  aria-haspopup="true"
                >
                  <UserIcon />
                  <Typography
                    sx={{
                      ...typographyStyles,
                    }}
                  >
                    Hi, {user?.firstName}
                  </Typography>
                </StackedIconWithText>
              </MHMenu>
            ) : (
              <NavBarLink onClick={() => history.push("/auth/login")}>
                Login
              </NavBarLink>
            )} */}
          </StackedRow>

          {/* <Typography
            sx={{
              display: { xs: "inline", sm: "none" },
              width: { xs: "100%", sm: "unset" },
              ...typographyStyles,
            }}
          >
            For Employers
          </Typography> */}
        </StackedRow>

        <Toolbar
          variant="dense"
          sx={{
            justifyContent: "space-between",
            position: "relative",
            // pl: { xs: 2, sm: 2, md: 2, lg: 0 },
            pr: { xs: 2, sm: 0, lg: 0 },
          }}
          disableGutters
        >
          {matchesLgDown ? (
            <IconButtonStyled
              onClick={toggleDrawer(true)}
              sx={{
                pl: 2,
              }}
            >
              <MenuIcon />
            </IconButtonStyled>
          ) : (
            <NavPrimaryLinks />
          )}

          <Box aria-label="menu" sx={{ ml: { xs: 0, sm: 0 } }}>
            <AbsolutePositionedContainer
              onClick={() => history.push("/")}
              sx={{
                cursor: "pointer",
              }}
            >
              <MHLogo />
              {/* <img
                src="CaringAfrica_logo.png"
                alt="Summit Image"
                // style={{
                //   width: "100%",
                //   height: "100%",
                // }}
                className=" h-12"
              /> */}
            </AbsolutePositionedContainer>
          </Box>

          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <IconButtonStyled
              onClick={() => {
                isSearchOpen ? handleCloseSearch() : handleOpenSearch();
              }}
            >
              <SearchIcon />
            </IconButtonStyled> */}

            <NavAction onClick={() => history.push("/newsletter")}>
              Newsletter
            </NavAction>
          </Stack>
        </Toolbar>
      </AppBarStyled>

      {isSearchOpen &&
        (matchesLgDown ? <MobileSearchPopout /> : <SearchPopout />)}

      <MobileSidebar
        variant="temporary"
        open={isDrawerOpen}
        anchor={anchor}
        onClose={toggleDrawer(false)}
        sx={{
          // height: '-webkit-fill-available',
          "& .MuiDrawer-paper": {
            height: "-webkit-fill-available",
          },
        }}
      >
        <Stack height="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2.5}
            height={70}
            borderBottom={1}
            borderColor="#F3F4F6"
          >
            <Typography variant="h3">Menu</Typography>

            <IconButtonStyled onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButtonStyled>
          </Stack>

          <Box
            // flexGrow={1}
            px={2.5}
            py={4}
            height={"calc(100vh - 140px)"}
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {/* <DropdownButton
              isOpen={openDialog}
              label="Offerings"
              onExpand={() => {}}
              sx={DROPDOWN_SX}
            /> */}
            <MobileSidebarLink to="/about">About</MobileSidebarLink>
            {/* <MobileSidebarLink to="/advisors">
              1:1 Guided Sessions
            </MobileSidebarLink> */}
            <DropdownButton
              isOpen={openSessionsToggleMenu}
              label="Focus Area"
              component="button"
              onExpand={() => {
                openSessionsToggleMenu
                  ? handleToggleSessions(false)()
                  : handleToggleSessions(true)();
              }}
              sx={DROPDOWN_SX}
            />
            {openSessionsToggleMenu && <GuidedSessionsDrawerDropdownContent />}

            <DropdownButton
              isOpen={openSummitToggleMenu}
              label="Summit"
              component="button"
              onExpand={() => {
                openSummitToggleMenu
                  ? handleToggleSummit(false)()
                  : handleToggleSummit(true)();
              }}
              sx={DROPDOWN_SX}
            />
            {openSummitToggleMenu && <SummitDrawerDropdownContent />}

            {/* <DropdownButton
              isOpen={openToggleMenu}
              label="Resources"
              component="button"
              onExpand={() => {
                openToggleMenu ? handleToggle(false)() : handleToggle(true)();
              }}
              sx={DROPDOWN_SX}
            />
            {openToggleMenu && <ResourcesDrawerDropdownContent />} */}

            {/* <MobileSidebarLink to="/leave-gap">Care Coverage</MobileSidebarLink>
            <MobileSidebarLink to="/individuals">For You</MobileSidebarLink>
            <MobileSidebarLink to="/employers">Employers</MobileSidebarLink> */}
            {/* <MobileSidebarLink to="/auth/login">Login</MobileSidebarLink> */}

            {/* {isAuthenticated && user ? null : (
              <MobileSidebarLink to="/auth/login">Login</MobileSidebarLink>
            )} */}

            {/* {isAuthenticated && user ? (
              <>
                {auxUserDetails?.subscriptionStatus === "active" && (
                  <>
                    <MobileSidebarLink to="https://employee-dashboard-v1-s757.vercel.app">
                      Dashboard
                    </MobileSidebarLink>
                    <MobileSidebarLink to="https://employee-dashboard-v1-s757.vercel.app/settings/profile">
                      Settings
                    </MobileSidebarLink>
                  </>
                )}
                <MobileSidebarLink
                  to="/"
                  onClick={(e) => {
                    toggleDrawer(false)(e);
                    logout();
                  }}
                  sx={{
                    "&": {
                      color: "#ff1744 !important",
                    },
                  }}
                >
                  Logout
                </MobileSidebarLink>
              </>
            ) : null} */}
          </Box>

          <NavAction
            onClick={() => history.push("/thecaregap")}
            show
            sx={{
              flexGrow: "unset",
              fontSize: "13.2965px",
              lineHeight: "92%",
              letterSpacing: "0.115em",
              height: 70,
            }}
          >
            The Care Gap
          </NavAction>
        </Stack>
      </MobileSidebar>
    </Box>
  );
};

export default AppBar;
