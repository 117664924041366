import React from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  matchPath,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Layout from "../components/Layout/Layout";
import Index from "../pages/Index";

import ResourcesNavigator from "./ResourcesNavigator";
import AuthNavigator from "./AuthNavigator";

import { FnComponent } from "../models/component.model";
import { StaticDataContextProvider } from "../store/context/static-data.context";
import Summit from "../pages/Summit";
import FAQ from "../pages/FAQ";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsCondition from "../pages/TermsCondition";
import ErrorPage from "../pages/404NotFound";
import Memberships from "../pages/Memberships";
import Checkout from "../components/Features/Checkout/Checkout";
import Cart from "../components/Features/Checkout/Cart";
import CartCheckout from "../components/Features/Checkout/CartCheckout";
import BrandPartnership from "../pages/BrandPartnership";

import { SearchContextProvider } from "../store/context/search-context";

import AuthContext from "../store/context/auth-context";
import Quotient from "../pages/Quotient";
import Africa from "../pages/Africa";

import Childcare from "../pages/Childcare";
import AgingCare from "../pages/AgingCare";
import DisabilityCare from "../pages/DisabilityCare";
import PastSummit from "../pages/PastSummit";
import { FIndCare } from "../pages/CareProvider/FindCare";
import ViewCare from "../pages/CareProvider/ViewCare";
import CareProviderMap from "../pages/CareProvider/CareProviderMap";

const MEMBERSIPS_PATHNAME = "/individuals";

const AppNavigator: FnComponent<{}> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const { isAuthenticated, openAuthDialog, closeAuthDialog } =
    React.useContext(AuthContext);

  function handleOnAuthSuccess() {
    history.replace(location.pathname);
  }

  function handleTerminateAuth() {
    /**
     * path matches that prevent redirect to homepage when auth dialog is terminated
     */
    const pathMatches = matchPath(window.location.pathname, {
      path: [
        "/individuals",
        "/auth/subscribe",
        "/auth/login",
        "/auth/forgot-password",
      ],
      exact: false,
      strict: false,
    });

    // console.log(window.location.pathname);

    if (!pathMatches) {
      history.replace("/");
    }
  }

  return (
    <React.Fragment>
      <StaticDataContextProvider>
        <SearchContextProvider>
          <Layout>
            {/* <TransitionGroup>
              <CSSTransition
                unmountOnExit
                key={location.pathname}
                classNames="fade"
                timeout={400}> */}
            <Switch location={location}>
              <Route path={`/`} exact>
                <Index />
                {/* <EmployersTempRedacted /> */}
              </Route>

              {/* <Route
                path={`/resources`}
                render={(routeProps) => {
                  return (
                    <GuardedRoute
                      isRouteAccessible={isAuthenticated}
                      protectedRoute={"/resources"}
                      redirectRoute={routeProps.location.pathname}
                      onDeny={() =>
                        openAuthDialog(handleOnAuthSuccess, handleTerminateAuth)
                      }
                      onDestroy={closeAuthDialog}
                      renderGuardedPage={true}
                      guardedPage={
                        <ResourcesNavigator
                          isComponentAccessible={isAuthenticated}
                        />
                      }
                    />
                  );
                }}
              /> */}

              {/* <Route path={`/resources`}>
                <ResourcesNavigator />
              </Route> */}

              {/* <Route path={`/employer-temp-fsdgfgdfh`} exact>
                <EmployersTempRedacted />
              </Route>
              <Route path={`/employers`} exact>
                <Employers />
              </Route> */}
              {/* <Route path={`/care`} exact>
                <CareWallet />
              </Route> */}
              <Route path={`/summit`} exact>
                <Summit />
              </Route>
              <Route path={`/recap-summit/:year/:slug`} exact>
                <PastSummit />
              </Route>
              {/* <Route path={`/africa/summit`} exact>
                <AfricaSummit />
              </Route> */}
              {/* <Route path={`/summit/partners/registration`} exact>
                <RegSummitPartner />
              </Route> */}
              {/* <Route path={`/summit/caring-workplace`} exact>
                <CaringWorkplace />
              </Route>
              <Route path={`/summit/caring-leader`} exact>
                <CaringLeader />
              </Route> */}
              {/* <Route path={`/summit/speaker-request`} exact>
                <SpeakerRequest />
              </Route> */}
              {/* <Route path={`/ourstory`} exact>
                <OurStory />
              </Route> */}
              <Route path={`/partner`} exact>
                <BrandPartnership />
              </Route>
              {/* <Route path={`/equity`} exact>
                <Equity />
              </Route> */}
              <Route path={`/newsletter`} exact>
                <Quotient />
              </Route>
              {/* <Route path={`/find-care`} exact>
                <FIndCare />
              </Route> */}
              {/* <Route path={`/view-care/:slug`} exact>
                <ViewCare />
              </Route> */}
              {/* <Route path={`/care-provider-locations`} exact>
                <CareProviderMap />
              </Route> */}
              <Route path={`/about`} exact>
                <Africa />
              </Route>
              <Route path={`/faq`} exact>
                <FAQ />
              </Route>
              <Route path={`/contact`} exact>
                <Contact />
              </Route>
              <Route path={`/privacy-policy`} exact>
                <PrivacyPolicy />
              </Route>
              <Route path={`/terms-and-condition`} exact>
                <TermsCondition />
              </Route>
              <Route path={`/childcare`} exact>
                <Childcare />
              </Route>
              <Route path={`/aging-care`} exact>
                <AgingCare />
              </Route>
              <Route path={`/disability-care`} exact>
                <DisabilityCare />
              </Route>
              {/* <Route path={`/care-academy`} exact>
                <CareAcademy />
              </Route>
              <Route path={`/advisors`}>
                <GuidanceSessionsNavigator />
              </Route>
              <Route path={`/cohort-sessions`}>
                <CohortSessionsNavigator />
              </Route> */}
              {/* <Route path={`/leave-gap`}>
                <PlacementsNavigator />
              </Route>
              <Route path={MEMBERSIPS_PATHNAME}>
                <Memberships />
              </Route>
              <Route path={`/checkout`}>
                <Checkout />
              </Route>
              <Route path={`/cart`}>
                <Cart />
              </Route>
              <Route path={`/cart-checkout`}>
                <CartCheckout />
              </Route>

              <Route path={`/auth`}>
                <AuthNavigator />
              </Route> */}
              <Route path={`*`}>
                <ErrorPage />
              </Route>
            </Switch>
            {/* </CSSTransition>
            </TransitionGroup> */}
          </Layout>
        </SearchContextProvider>
      </StaticDataContextProvider>
    </React.Fragment>
  );
};

export default AppNavigator;
