import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography, { typographyClasses } from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import Membership from "../components/Features/Landing/MembershipWidget";
import MembershipPlans from "../components/Features/Memberships/MembershipPlans";
import MembershipFeatures from "../components/Features/Memberships/MembershipFeatures";

import Divider from "@mui/material/Divider";

import FollowMH from "../components/Landing/FollowMH";
import MHFooter from "../components/Layout/MHFooter";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHButton from "../components/Common/Button/MHButton";
import { useHistory } from "react-router-dom";
import EnterprisePlan from "../components/Landing/EnterprisePlan";
import PlatformFeatures from "../components/Features/Memberships/PlatformFeatures";
import { MagazineFeature } from "./Employers";
import ImageGrid from "../components/Story/ImageGrid";

const DisabilityCare = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  const membershipPlansDivRef = React.useRef<{
    divEl: HTMLDivElement | null;
  }>({ divEl: null });

  const vision = [
    {
      caption: "Advocating for Inclusive Policies:",
      content:
        "Working towards policy reforms that ensure equal access to healthcare and services for people with disabilities.",
    },
    {
      caption: "Improving Healthcare Accessibility:",
      content:
        "Striving to remove physical, financial, and informational barriers to healthcare for people with disabilities.        ",
    },
    {
      caption: "Empowering Through Education and Training:",
      content:
        "Providing information and training to maximize the health of individuals with disabilities, as well as educating healthcare providers to better meet their needs. ",
    },
  ];

  return (
    <React.Fragment>
      <Grid container direction={{ xs: "column", md: "row" }} mb={4}>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={500}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              "& > p": {
                textAlign: "center",
                pointerEvents: "none",
              },
            }}
          >
            <Typography
              variant="caption"
              textTransform="uppercase"
              fontSize="11px"
              letterSpacing="0.1em"
              lineHeight="230%"
              sx={{
                mb: 5,
              }}
              paragraph
            >
              Disability Care
            </Typography>

            <Typography
              variant="h1"
              fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
              textTransform="capitalize"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Join Us in Our Mission
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              fontSize="13px"
              lineHeight="185%"
              letterSpacing="0.02em"
              sx={{
                mb: 4,
              }}
              paragraph
            >
              Together, we can create an Africa where disability is not a
              barrier to living a full and healthy life. Support Caring Africa
              in our mission to transform disability care across the continent,
              fostering a more inclusive and equitable society.
            </Typography>

            {/* <MHButton
              sx={{
                width: "fit-content",
              }}
              onClick={() =>
                membershipPlansDivRef.current?.divEl?.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              More Info
            </MHButton> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              "& > img": {
                height: 600,
                width: "100%",
                objectPosition: "bottom",
                objectFit: "cover",
              },
            }}
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/addprosper3.jpg"
              alt="Banner"
              width="700"
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        py={7}
        px={{ xs: 2, md: 6, lg: 8 }}
        color="primary.main"
        sx={{
          [`& > .${typographyClasses.root}`]: {
            // color: '#EDD5B1',
            textAlign: "center",
          },
        }}
      >
        {/* <Typography
          variant="caption"
          fontSize="12px"
          lineHeight="102%;"
          letterSpacing="0.1em"
          paragraph
        >
          childcare
        </Typography> */}

        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          Embracing Inclusivity: Transforming Disability Care in Africa
        </Typography>
      </Box>

      {/* <Divider
        variant="middle"
        sx={{
          borderColor: "primary.main",
          width: "91%",
          mx: "auto",
          opacity: 0.1,
          my: 8,
        }}
      /> */}

      <ImageGrid
        title="Understanding Disability in Africa        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/disability1.png"
        content="Disability in Africa is a multifaceted issue, reflecting the complex interplay between individual impairments and societal barriers. It's more prevalent among women, older people, children, and those living in poverty. These individuals often face deprived living conditions and lack access to essential services, leading to heightened risks for both infectious and non-infectious diseases​​.        "
        content2="hidden"
      />
      <ImageGrid
        title="Challenges in Healthcare for People with Disabilities        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last lg:order-first"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/disability2.png"
        content="People with disabilities in Africa are particularly vulnerable to deficiencies in healthcare services. They often do not receive the necessary health care, with many unable to afford it. This leads to higher vulnerability to secondary and co-morbid conditions, engaging in health risk behaviors, and increased rates of premature death​​. They face numerous barriers in accessing health care, including prohibitive costs, limited service availability, physical barriers, and inadequate skills and knowledge of health workers​​.        "
        content2="hidden"
      />

      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          Our Commitment at Caring Africa
        </Typography>

        <Typography
          variant="body2"
          fontSize={{ xs: "13px", md: "18px" }}
          lineHeight={{ xs: "200%", md: "169%" }}
          letterSpacing="0.02em"
          width={{ xs: "90%", md: "60%" }}
          mx="auto"
          mb={4}
        >
          At Caring Africa, we recognize the urgent need for transformative
          change in disability care. We are committed to:
        </Typography>

        {/* Web */}
        <Box className=" hidden md:block mb-20">
          <Grid container spacing={3} className=" mx-auto place-content-center">
            {vision.map((obj, index) => (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="flex gap-2 py-2 w-[95%]">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" text-start"
                    key={index}
                  >
                    <b className="font-areaNorm"> {obj.caption} </b>{" "}
                    {obj.content}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Mobile */}
        <Box
          // variant="body2"
          color="primary"
          fontSize={{ xs: "13px", sm: "14px" }}
          lineHeight={{ xs: "185%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="md:w-[600px] w-[340px] block md:hidden pb-10 mx-auto text-start mt-6 px-4"
        >
          {vision.map((obj, index) => (
            <Box className="flex gap-2 py-2">
              •
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "13px", sm: "14px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className=" text-start"
                key={index}
              >
                <b className="font-areaNorm"> {obj.caption} </b>
                {obj.content}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <MHSubscribe />

      <MHFooter />
    </React.Fragment>
  );
};

export default DisabilityCare;
