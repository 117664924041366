import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React from "react";

import { ReactComponent as EventIcon } from "../../static/svg/eventicon.svg";

type Props = {};

const SummitMotto = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="bg-navy-900 h-[384px] lg:h-[306px]">
        <Box className="py-12 md:py-8 lg:py-12">
          <EventIcon className="mx-auto h-8 w-8" />
          <Typography
            variant="h2"
            fontSize={{ xs: "24px", sm: "30px", md: "32px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            className="py-10 lg:py-8 w-[88%] md:w-[75%] lg:w-[60%] text-center mx-auto"
            color={"white"}
            justifyContent="center"
            alignItems="center"
          >
            This summit is more than a conference; it's a movement. A movement
            towards building a future where care is integrated seamlessly into
            the fabric of our professional and personal lives, enabling growth,
            equity, and well-being.
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SummitMotto;
