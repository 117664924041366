import { Box, Grid, Typography } from "@mui/material";
import React from "react";

type Props = {};

const vision = [
  {
    caption:
      "We envision a future where comprehensive care - encompassing childcare, aging care, disability, and long-term care - is seamlessly integrated into Africa's socio-economic fabric.    ",
  },
  {
    caption:
      "Aiming for a society where care infrastructure not only supports individual well-being but also fuels scalable businesses and inclusive economic development.    ",
  },
  {
    caption:
      "Envisioning an Africa where the care economy is a recognized and integral part of the investment landscape, driving economic growth and gender equity.    ",
  },
  {
    caption:
      "Seeking a future where women's participation in all sectors, enhanced by equitable care responsibilities, unlocks Africa's full potential.    ",
  },
  {
    caption:
      "Striving for a society where the care economy is a cornerstone of economic growth, gender equity, and sustainable development.    ",
  },
];
const mission = [
  {
    caption:
      "Promoting and supporting a broad spectrum of care services, including childcare, aging care, disability care, and long-term care, recognizing their fundamental role in society.    ",
  },
  {
    caption:
      "Advocating for fair and accessible paid leave policies, ensuring that care responsibilities do not hinder economic participation, especially for women.    ",
  },
  {
    caption:
      "Driving forward the agenda of care equity and the formalization of the care economy, ensuring that care workers are recognized and supported.    ",
  },
  {
    caption:
      "Building an understanding of the care economy as a key sector for investment and economic growth in Africa.    ",
  },
  {
    caption:
      "Balancing care responsibilities to enable women's fuller participation in scalable businesses, economic activities, and political arenas.    ",
  },
  {
    caption:
      "Collaborating with diverse stakeholders to create an ecosystem where care work supports broader socio-economic objectives and gender equity.    ",
  },
];

const AboutAfrica = (props: Props) => {
  return (
    <Box mb={10}>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "14px", sm: "14px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          What is Caring Africa?
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "16px", sm: "16px", md: "16px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          Caring Africa is powering the care revolution in Africa by building
          the care infrastructure necessary for business and economic growth,
          bridging the care gap to foster gender equity, and formalizing the
          care economy for a sustainable society. Through advocacy for equitable
          care policies, we empower workplaces and communities to unlock
          Africa's full potential.
        </Typography>
      </Box>

      <Grid container spacing={6} className="">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="text-center mt-10 overflow-hidden">
            <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "14px", sm: "14px" }}
              lineHeight={{ xs: "230%", sm: "102%" }}
              letterSpacing={"0.1em"}
              className="uppercase w-[335px]"
            >
              Vision
            </Typography>

            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "180%", sm: "180%" }}
              letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
              className=" w-[335px] md:w-full pt-4 text-center mx-auto"
            >
              Envisioning a Future Where Care <br /> Empowers Every Aspect of
              Africa's Growth
            </Typography>

            <Box
              // variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[600px] w-[340px] pb-10 mx-auto text-start mt-6 px-4"
            >
              {vision.map((obj, index) => (
                <Box className="flex gap-2 py-2">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" text-start"
                    key={index}
                  >
                    {/* <b className="font-areaNorm"> {obj.caption} </b> */}
                    {obj.caption}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="text-center mt-10 overflow-hidden">
            <Typography
              variant="caption"
              color="primary"
              fontSize={{ xs: "11px", sm: "12px" }}
              lineHeight={{ xs: "230%", sm: "102%" }}
              letterSpacing={"0.1em"}
              className="uppercase w-[335px] md:w-[105px]"
            >
              Mission
            </Typography>
            <Typography
              variant="subtitle2"
              color="primary"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "180%", sm: "180%" }}
              letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
              className=" w-[335px] md:w-full pt-4 text-center mx-auto"
            >
              Championing Care as the Catalyst for <br /> Africa's Economic and
              Social Transformation
            </Typography>

            <Box
              // variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[600px] w-[340px] pb-10 mx-auto text-start mt-6 px-4"
            >
              {mission.map((obj, index) => (
                <Box className="flex gap-2 py-2">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" text-start"
                    key={index}
                  >
                    {/* <b className="font-areaNorm"> {obj.caption} </b> */}
                    {obj.caption}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutAfrica;
