import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import StatsCard from "./StatsCard";

// type Props = {}

const SummitStats = () => {
  return (
    <React.Fragment>
      <Stack
        className="summit-stats-component  hidden lg:flex overflow-x-hidden overscroll-x-none"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase text-center"
        >
          We Are Closing The Care Gap For Working Families, Workplaces, And
          Economies
        </Typography>

        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="py-8 text-center "
        >
          Here's Why
        </Typography>

        <Box className="lg:flex lg:justify-center lg:gap-10 ">
          <StatsCard
            stats="263M"
            info="Nigeria has the largest population in Africa, and it is one of the most rapidly growing populations in the world. By 2030, the population is projected to grow by 50 million people, from 213 million people to 263 million people, and more women will need affordable childcare to enter and remain in the workforce (United Nations 2022).             "
          />
          <StatsCard
            stats="165M"
            info="Of sub-Saharan African adults over 60yr by 2050. While the African population, like the global population, continues to age, extended family support is gradually eroding. It is therefore imperative that we design innovative measures to improve long-term care for older people.            "
          />
          <StatsCard
            stats="$23B"
            info=" In 2022, the World Bank estimated that closing the gender gap in key sectors of Nigeria’s economy could yield an economic gain of as much as $23 billion, or 6 percent of Nigeria’s overall GDP.            "
          />
          {/* <StatsCard
            stats="40%"
            info="Of the workforce missed work in October 2022 due to childcare shortages (Census Bureau)"
          /> */}
        </Box>
      </Stack>

      {/* Tab & Mobile */}

      <Stack
        className="summit-stats-component-tabmobile lg:hidden flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "120%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase text-center"
        >
          We Are Closing The Care Gap For Working Families, Workplaces, And
          Economies{" "}
        </Typography>

        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="py-8  text-center w-[90%]"
        >
          Here's Why
        </Typography>

        <Box className="block">
          <StatsCard
            stats="263M"
            info="Nigeria has the largest population in Africa, and it is one of the most rapidly growing populations in the world. By 2030, the population is projected to grow by 50 million people, from 213 million people to 263 million people, and more women will need affordable childcare to enter and remain in the workforce (United Nations 2022).             "
          />
          <StatsCard
            stats="165M"
            info="Of sub-Saharan African adults over 60yr by 2050. While the African population, like the global population, continues to age, extended family support is gradually eroding. It is therefore imperative that we design innovative measures to improve long-term care for older people.            "
          />
          <StatsCard
            stats="$23B"
            info=" In 2022, the World Bank estimated that closing the gender gap in key sectors of Nigeria’s economy could yield an economic gain of as much as $23 billion, or 6 percent of Nigeria’s overall GDP.            "
          />
          {/* <StatsCard
            stats="40%"
            info="Of women want longer paid maternity leave (IFC)"
          /> */}
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default SummitStats;
