import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MHButton from "../Common/Button/MHButton";
import SponsorsCard from "./SponsorsCard";
import { useHistory } from "react-router-dom";

type Props = {};

const SummitMedia = (props: Props) => {
  let history = useHistory();
  // console.log("PathName: ", history.location.pathname);

  return (
    <React.Fragment>
      {history.location.pathname.includes("2024/caring-africa-summit") ? (
        <section id="summitmedia" className="py-10">
          <Typography
            variant="caption"
            fontSize={{ xs: "11px", sm: "11px" }}
            fontWeight={900}
            lineHeight={{ xs: "100%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className=" py-6 uppercase text-center flex justify-center"
          >
            A Glance of the summit
          </Typography>
          <Box>
            <iframe
              // src="https://www.youtube.com/embed/Ifld-VPhYgg"
              src={
                "https://player.vimeo.com/video/922712818?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              }
              title={"CARING AFRICA SUMMIT RECAP VIDEO"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-[400px] md:h-[400px] lg:h-[700px]"
            ></iframe>
          </Box>
        </section>
      ) : null}
    </React.Fragment>
  );
};

export default SummitMedia;
