import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as BgOverlay } from "../static/svg/quotient.svg";
import QuotientHeader from "../components/Quotient/QuotientHeader";
import AboutQuotient from "../components/Quotient/AboutQuotient";
import Expectations from "../components/Quotient/Expectations";
import PodSubscribe from "../components/Layout/PodSubscribe";
import QuotientSubscribe from "../components/Quotient/QuotientSubscribe";
import MHFooter from "../components/Layout/MHFooter";
import Retriever from "../components/Summit/Retriever";

type Props = {};

const Quotient = (props: Props) => {
  return (
    <React.Fragment>
      <QuotientHeader
        headerClassName="relative text-center pl-14 z-30  md:pl-0 h-[450px] md:h-[450px] lg:h-auto w-full mx-auto bg-[#FFDA9C] overflow-hidden"
        majorClassName="md:absolute z-50 top-8 md:top-12 md:inset-x-[18%] lg:top-24 lg:inset-x-[55%] text-center place-content-center"
        boxClassName="md:place-content-center pl-20 md:pl-0 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        title="Ceo Insight"
        titleInfo="The Care Gap"
        titleInfoclassName="text-center w-[60%] md:w-full font-columbia text-5xl capitalize font-semibold"
        pageInfo="Where Care Defines The Balance: Redefining work and life in our caregiving era, I bring weekly insights that propel progress at home, in workplaces, and across society."
        podClassName="hidden"
        eventSocialClassName="md:absolute md:bottom-24 lg:transform md:-translate-x-2/4 lg:translate-x-[250px] mt-8 items-center -ml-14 md:ml-0 "
        pageInfoClassName=" pt-4 w-[70%] md:w-full md:ml-2 -ml-8"
        ResIconUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1678056764/Vector_h4qbql.png"
        BgUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/BlessingQuotient+2.png"
      >
        <BgOverlay
          className="overflow-hidden hidden md:block absolute top-12 -right-20"
          height="500px"
          width="500px"
        />
        <BgOverlay
          className="overflow-hidden md:hidden block absolute -z-50 top-9 left-[140px]"
          height="500px"
          width="500px"
        />
      </QuotientHeader>

      <AboutQuotient />
      <Expectations />
      <PodSubscribe />
      <QuotientSubscribe />
      <MHFooter />
    </React.Fragment>
  );
};

export default Quotient;
